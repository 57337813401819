import React from "react";
import CreateCongeCard from "../../../components/organism/CreateCongeCard";
import UserNameImgCard from "../../../components/molecules/UserNameImgCard";
import { generatePicUrl } from "../../../_helpers/PictureHelpers";
import TextData from "../../../components/atoms/TextData";
import { useEmployees } from "../../../hooks/useEmployees";
import {
    destructFields,
    generateFields,
    Validation,
} from "../../../_helpers/FormHelpers";
import moment from "moment";
import { useHoliday } from "../../../hooks/useHolidays";
import { useNotificationContext } from "../../../Context/Notification";
import Swal from "sweetalert2";
import DatepickerInput from "../../../components/atoms/DatepickerInput";
import ProfilePicture from "../../../components/molecules/ProfilePicture";
import { employeeRoleColor } from "../../../_helpers/Functions";
import { ROUTER } from "../../../constants/env";

const CreateConge = ({
    onOpen,
    onClose,
    employees,
    licenseId,
    siteId ,
}: VacationRequestInterface) => {
    const { createConge, getHolidays, error } = useHoliday();
    const { addNotification } = useNotificationContext();

    const [employeesOptions, setEmployeesOptions] = React.useState([
        {
            label: (
                <TextData
                    width="auto"
                    fontWeight="600"
                    color="#101844"
                    size="12px"
                >
                    Aucun employee
                </TextData>
            ),
            value: "",
        },
    ]);
    const [congeData, setCongeData] = React.useState<any>(null);
    React.useEffect(() => {
        if (onOpen) {
            const newState = generateFields([
                { name: "employeeId", value: undefined },
                { name: "typeConge", value: undefined },
                { name: "typeday", value: true },
                { name: "startDate", value: moment() },
                { name: "endDate", value: moment() },
                { name: "fromHour", value: "00:00" },
                { name: "toHour", value:  "23:59" },
                { name: "note", value: "" },
            ]);

            setCongeData(newState);
            validate(newState);
        }
    }, [onOpen]);

    const pictureProps = {
        width: "30px",
        height: "30px",
        borderRadius: "50%",
        style: { borderRadius: "50%" },
    };
    
    React.useEffect(() => {
        let newEmps: any = [];
        const sortedEmployees = [...employees].sort((a, b) => {
            const nameA = `${a.fname} ${a.lname}`;
            const nameB = `${b.fname} ${b.lname}`;
            return nameA.localeCompare(nameB);
        });
        for (let i = 0; i < sortedEmployees?.length; i++) {
            const { fname, lname, _id, picture, role } = sortedEmployees[i];
            newEmps.push({
                label: fname + " " + lname,
                value: _id,
                icon: (
                    <div
                        style={{
                            marginRight: "8px",
                        }}
                    >
                        <ProfilePicture
                            profileImage={picture}
                            firstName={fname}
                            lastName={lname}
                            pictureProps={{
                                ...pictureProps,
                                imgPath: ROUTER.STATIC_IMG(picture),
                            }}
                            altColor={employeeRoleColor(role)}
                        />
                    </div>
                ),
            });
        }
        setEmployeesOptions(newEmps);
    }, [employees]);

    const congeTypes = [
        { label: "Arrêt maladie", value: "arret_maladie" },
        { label: "Congé maternité", value: "conge_maternite" },
        { label: "Congé paternité", value: "conge_paternite" },
        { label: "Congé payé", value: "paid_leave" },
        { label: "Congé sans solde", value: "unpaid_leave" },
        { label: "Évènement familial", value: "family_event" },
    ];
    const onSubmit = () => {
        const values = destructFields(congeData);

        if (values?.typeday === false) {
            values.endDate = values.startDate;
        }
        createConge({
            userId: values?.employeeId,
            fromDay: moment(values?.startDate)?.toISOString(),
            fromHour: values?.fromHour,
            toDay: moment(values?.endDate)?.toISOString(),
            toHour: values?.toHour,
            allDay: values?.typeday,
            type: values?.typeConge,
            message: values?.note,
            licenseId: licenseId,
        })
            .then((res: any) => {
                const data: any = res.payload;
                if (data?.status === "success") {
                    addNotification({
                        title: "Ajout d'un congé",
                        message: "Congé ajouté avec succès",
                        type: "success",
                    });
                    getHolidays(null, "0", null, null, licenseId, siteId ? [siteId]: null);
                }
                handleDelete();
            })
            .catch(() => {
                if (error) {
                    Swal.fire({
                        title: "Erreur!",
                        text: error, // Display error from useHoliday
                        icon: "error",
                        timer: 1500,
                        timerProgressBar: true,
                    });
                } else {
                    Swal.fire({
                        title: "Erreur!",
                        text: "Une erreur s'est produite lors de la soumission.",
                        icon: "error",
                        timer: 1500,
                        timerProgressBar: true,
                    });
                }
            });
    };

    const onChange = (field: string, value: any) => {
        if (congeData) {
            const newState = {
                ...congeData,
                [field]: {
                    ...congeData[field],
                    value,
                },
            };
            setCongeData(newState);
            validate(newState);
        }
    };

    const onBlur = (field: string) => {
        if (congeData) {
            const newState = {
                ...congeData,
                [field]: {
                    ...congeData[field],
                    touched: true,
                },
            };
            setCongeData(newState);
            validate(newState);
        }
    };

    const validate = (congeData: any) => {
        if (congeData) {
            new Validation()
                .add(congeData.employeeId, [
                    { REQUIRED: true, message: "Ce champ est obligatoire" },
                ])
                .add(congeData.typeConge, [
                    { REQUIRED: true, message: "Ce champ est obligatoire" },
                ])
                .validate((res: any) => {
                    setCongeData((prev: any) => ({
                        ...prev,
                        ...generateFields(res),
                    }));
                });
        }
    };

    const handleDelete = () => {
        setCongeData(null);

        if (onClose && typeof onClose === "function") {
            onClose();
        }
    };

    return (
        <div>
            {congeData && (
                <CreateCongeCard
                    onOpen={onOpen}
                    employeesOptions={employeesOptions}
                    onChange={onChange}
                    onBlur={onBlur}
                    congeData={congeData}
                    congeTypes={congeTypes}
                    onSubmit={onSubmit}
                    onReset={handleDelete}
                />
            )}
        </div>
    );
};

interface VacationRequestInterface {
    employees: any[];
    onOpen: boolean;
    onClose?: () => void;
    licenseId: string;
    siteId : any;
}
export default CreateConge;
