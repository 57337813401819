import * as React from "react";
import Modal from "../../atoms/Modal";
import PopUpCard from "../../molecules/PopUpCard";
import TextData from "../../atoms/TextData";
import Button from "../../atoms/Button";
import "./style.scss";

import Photo from "./photo1.png";
import Picture from "../../atoms/Picture";
import DotTicket from "../../atoms/DotTicket";
import SelectInput from "../../atoms/SelectInput";
import Input from "../../atoms/Input";

import NewTicket from "../../atoms/NewTicket";
import CircledRadio from "../../atoms/CircledRadio";
import ToggleButton from "../../atoms/Toggle";
import NewShiftTicketCard from "../NewShiftTicketCard";
import {
    employeeRoleColor,
    fomatStartEndTime,
} from "../../../_helpers/Functions";
import ProfilePicture from "../../molecules/ProfilePicture";
import { ROUTER } from "../../../constants/env";
import { usePlanning } from "../../../hooks/usePlanning";
import moment from "moment";
import { TRUE } from "sass";
import Swal from "sweetalert2";
import ShiftTimeInputs from "./ShiftTimeInputs";

function AddShift(props: any) {
    const {
        withEmployees,
        employeesOptions,
        defaultEmployee,
        shiftData,
        setShiftData,
        dayOrder,
        isShiftEditing,
        day,
    } = props;
    const { addNewSteakers, planningStartOfWeek, steakers, siteSettingsEtab } =
        usePlanning();
    const handleAddNewSteaker = async (data: any) => {
        let ok = await addNewSteakers(data);
        if (ok) {
            setAddNewTicket(false);
        }
    };

    const handleEmployeeId = (value: any) => {
        setShiftData((prev: any) => ({ ...prev, employeeId: value }));
    };
    const TicketsList = [
        { label: "Cuisine", color: "#FF6632" },
        { label: "Serveur", color: "#8833FF" },
        { label: "Bandol", color: "#CC7429" },
        { label: "Commis", color: "#33BFFF" },
        { label: "Légumes", color: "#2DC5AE" },
        { label: "Caissier", color: "#E62E7B" },
    ];
    const [shiftDays, setShiftDays] = React.useState([
        {
            name: "Lun",
            index: 1,
            checked: false,
            day: moment(planningStartOfWeek).add(0, "day").format("YYYY-MM-DD"),
        },
        {
            name: "Mar",
            index: 2,
            checked: false,
            day: moment(planningStartOfWeek).add(1, "day").format("YYYY-MM-DD"),
        },
        {
            name: "Mer",
            index: 3,
            checked: false,
            day: moment(planningStartOfWeek).add(2, "day").format("YYYY-MM-DD"),
        },
        {
            name: "Jeu",
            index: 4,
            checked: false,
            day: moment(planningStartOfWeek).add(3, "day").format("YYYY-MM-DD"),
        },
        {
            name: "Ven",
            index: 5,
            checked: false,
            day: moment(planningStartOfWeek).add(4, "day").format("YYYY-MM-DD"),
        },
        {
            name: "Sam",
            index: 6,
            checked: false,
            day: moment(planningStartOfWeek).add(5, "day").format("YYYY-MM-DD"),
        },
        {
            name: "Dim",
            index: 7,
            checked: false,
            day: moment(planningStartOfWeek).add(6, "day").format("YYYY-MM-DD"),
        },
    ]);
    React.useEffect(() => {
        let order = moment(day).day();
        order = order === 0 ? 7 : order;
        order--;
        setShiftDays((prev: any) => {
            let updatedPrev = prev;
            updatedPrev[order].checked = true;
            return updatedPrev;
        });
        console.log("shiftDays = ", shiftDays);
        console.log("shiftDays[order] = ", shiftDays[order]);
        setShiftData((prev: any) => ({
            ...prev,
            days: [{ ...shiftDays[order] }],
        }));
    }, [day]);
    const [addNewTicket, setAddNewTicket] = React.useState(false);

    const [pauseDisabled, setPauseDisabled] = React.useState(false);
    React.useEffect(() => {
        setPauseDisabled(
            //editedShift &&
            Number(siteSettingsEtab.pause) === Number(shiftData?.pause),
        );
    }, [/*editedShift, */ siteSettingsEtab.pause, shiftData?.pause]);

    const [simpleSteaker, setSimpleSteakers] = React.useState<any>([]);
    const [withTimeSteaker, setWithTimeSteakers] = React.useState<any>([]);
    React.useEffect(() => {
        if (steakers?.length > 0) {
            setSimpleSteakers([
                ...steakers?.filter(
                    (steaker: any) => !steaker.fromHour && !steaker.toHour,
                ),
            ]);
            setWithTimeSteakers([
                ...steakers?.filter(
                    (steaker: any) => steaker.fromHour || steaker.toHour,
                ),
            ]);
        }
    }, [steakers]);

    return (
        <div className="add-shift-container">
            {withEmployees && (
                <div
                    className="emp-selection-container"
                    style={{
                        gap: 10,
                        marginBottom: 20,
                        width: "100%",
                    }}
                >
                    <SelectInput
                        title="Employés"
                        captionColor="#2a346d"
                        options={employeesOptions}
                        selectedValue={employeesOptions.find(
                            (option: any) =>
                                option.value === defaultEmployee._id,
                        )}
                        onSelectOption={(option) =>
                            handleEmployeeId(option.value)
                        }
                    />
                </div>
            )}
            <ShiftTimeInputs
                shiftData={shiftData}
                setShiftData={setShiftData}
                pauseDisabled={pauseDisabled}
                setPauseDisabled={setPauseDisabled}
                isShiftEditing={isShiftEditing}
                planningDefaultPause={siteSettingsEtab?.pause}
            />

            <div className="add-tickets-container">
                <TextData
                    variant="label"
                    size="12px"
                    fontWeight="500"
                    color="#2a346d"
                >
                    Choisir une étiquette
                </TextData>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        marginTop: 5,
                        width: "100%",
                    }}
                >
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            flexWrap: "wrap",
                            gap: "20px",
                            width: "100%",
                            margin: "3px 0",
                        }}
                    >
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                flexWrap: "wrap",
                                gap: 8,
                                width: "100%",
                            }}
                        >
                            {simpleSteaker?.map((ticket: any, index: any) => {
                                return (
                                    <DotTicket
                                        key={index}
                                        color={ticket.color}
                                        selected={
                                            shiftData?.steaker === ticket._id
                                        }
                                        onClick={() => {
                                            setShiftData((prev: any) => ({
                                                ...prev,
                                                steaker:
                                                    prev.steaker === ticket._id
                                                        ? ""
                                                        : ticket._id,
                                                // from: ticket.fromHour
                                                //     ? ticket.fromHour
                                                //     : prev.from,
                                                // to: ticket.toHour
                                                //     ? ticket.toHour
                                                //     : prev.to,
                                            }));
                                        }}
                                        style={{
                                            width: "calc(calc(100% - 16px) / 3)",
                                        }}
                                    >
                                        <div
                                            style={{
                                                width: "100%",
                                                display: "flex",
                                                flexDirection:
                                                    ticket.name?.length <= 15
                                                        ? "row"
                                                        : "column",
                                                justifyContent:
                                                    ticket.name?.length <= 15
                                                        ? "flex-start"
                                                        : "center",
                                                alignItems:
                                                    ticket.name?.length <= 15
                                                        ? "center"
                                                        : "flex-start",
                                                gap: "3px",
                                            }}
                                        >
                                            <div
                                                style={{
                                                    fontSize: "medium",
                                                }}
                                            >
                                                {ticket.name.slice(0, 15)}
                                                {ticket.name?.length > 15 &&
                                                    "..."}
                                            </div>
                                            {(ticket.fromHour ||
                                                ticket.toHour) && (
                                                <div
                                                    style={{
                                                        fontSize: "small",
                                                        fontWeight: "bold",
                                                    }}
                                                >
                                                    (
                                                    {ticket.fromHour !== ""
                                                        ? ticket.fromHour
                                                        : " ... "}
                                                    {" - "}
                                                    {ticket.toHour !== ""
                                                        ? ticket.toHour
                                                        : " ... "}
                                                    )
                                                </div>
                                            )}
                                        </div>
                                    </DotTicket>
                                );
                            })}
                        </div>
                        <hr />
                        <TextData
                            variant="label"
                            size="12px"
                            fontWeight="500"
                            color="#2a346d"
                        >
                            Étiquette avec heure prédéfinie
                        </TextData>
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                flexWrap: "wrap",
                                gap: 8,
                                width: "100%",
                            }}
                        >
                            {withTimeSteaker?.map((ticket: any, index: any) => {
                                return (
                                    <DotTicket
                                        key={index}
                                        color={ticket.color}
                                        selected={
                                            shiftData?.steaker === ticket._id
                                        }
                                        onClick={() => {
                                            setShiftData((prev: any) => ({
                                                ...prev,
                                                steaker:
                                                    prev.steaker === ticket._id
                                                        ? ""
                                                        : ticket._id,
                                                from: ticket.fromHour
                                                    ? ticket.fromHour
                                                    : prev.from,
                                                to: ticket.toHour
                                                    ? ticket.toHour
                                                    : prev.to,
                                            }));
                                        }}
                                        style={{
                                            width: "calc(calc(100% - 8px) / 2)",
                                        }}
                                    >
                                        <div
                                            style={{
                                                width: "100%",
                                                display: "flex",
                                                flexDirection:
                                                    ticket.name?.length <= 15
                                                        ? "row"
                                                        : "column",
                                                justifyContent:
                                                    ticket.name?.length <= 15
                                                        ? "flex-start"
                                                        : "center",
                                                alignItems:
                                                    ticket.name?.length <= 15
                                                        ? "center"
                                                        : "flex-start",
                                                gap: "3px",
                                            }}
                                        >
                                            <div
                                                style={{
                                                    fontSize: "medium",
                                                }}
                                            >
                                                {ticket.name.slice(
                                                    0,
                                                    ticket.fromHour ||
                                                        ticket.toHour
                                                        ? 15
                                                        : 20,
                                                )}
                                                {ticket.name?.length > 15 &&
                                                    "..."}
                                            </div>
                                            {(ticket.fromHour ||
                                                ticket.toHour) && (
                                                <div
                                                    style={{
                                                        fontSize: "small",
                                                        fontWeight: "bold",
                                                    }}
                                                >
                                                    (
                                                    {ticket.fromHour !== ""
                                                        ? ticket.fromHour
                                                        : " ... "}
                                                    {" - "}
                                                    {ticket.toHour !== ""
                                                        ? ticket.toHour
                                                        : " ... "}
                                                    )
                                                </div>
                                            )}
                                        </div>
                                    </DotTicket>
                                );
                            })}
                        </div>
                    </div>
                    <NewTicket
                        width="154px"
                        text="Nouvelle étiquette"
                        textColor="#4C5690"
                        textSize="12px"
                        iconColor="#4C5690"
                        handleAddNewTicket={() => setAddNewTicket(true)}
                    />
                    <Modal
                        modalEnabled={addNewTicket}
                        onClose={() => setAddNewTicket(false)}
                    >
                        <NewShiftTicketCard
                            onClose={() => setAddNewTicket(false)}
                            displayColorsCount={999}
                            onSubmitButton={handleAddNewSteaker}
                        />
                    </Modal>
                </div>
            </div>
            {!isShiftEditing && (
                <div className="repeat-shifts-container">
                    <TextData
                        width="auto"
                        size="12px"
                        fontWeight="500"
                        color="#2a346d"
                        style={{
                            marginTop: 35,
                            marginBottom: 5,
                        }}
                    >
                        Répéter ce shift sur plusieurs jours
                    </TextData>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            flexWrap: "nowrap",
                            gap: 19,
                        }}
                    >
                        {shiftDays.map(({ name, index, checked, day }) => {
                            return (
                                <CircledRadio
                                    id={index}
                                    key={index}
                                    thisDay={day}
                                    text={name}
                                    selected={checked}
                                    onClick={(id, checkedValue) => {
                                        setShiftData((prev: any) => {
                                            let exist = false;
                                            let updatedData = prev.days.map(
                                                (day: any) => {
                                                    if (day.index === id) {
                                                        exist = true;
                                                        return {
                                                            ...day,
                                                            checked:
                                                                checkedValue,
                                                        };
                                                    } else {
                                                        return day;
                                                    }
                                                },
                                            );
                                            if (!exist) {
                                                updatedData.push({
                                                    name,
                                                    index,
                                                    checked: checkedValue,
                                                    day,
                                                });
                                            }
                                            return {
                                                ...prev,
                                                days: updatedData,
                                            };
                                        });
                                    }}
                                />
                            );
                        })}
                    </div>
                </div>
            )}
            <div
                className="text-area-shift-container"
                style={{
                    width: "100%",
                }}
            >
                <Input
                    captionText="Notes"
                    captionColor="#2a346d"
                    placeholder="Ecrire ici..."
                    value={shiftData?.comment}
                    onChange={(value: string) =>
                        setShiftData((prev: any) => ({
                            ...prev,
                            comment: value,
                        }))
                    }
                    inputWidth="100%"
                    captionSize="12px"
                    inputRadius="4px"
                    textColor="#4C5690"
                    textSize={14}
                    iconLocation="right"
                    borderColor="#C7CBE2"
                    borderWidth={1}
                    style={{
                        inputStyle: {
                            minHeight: "48px",
                            fontWeight: "400",
                            padding: "10px",
                        },
                        captionStyle: {
                            fontWeight: "500",
                            marginTop: 22,
                        },
                    }}
                />
            </div>
        </div>
    );
}

function AddAbsence(props: any) {
    const {
        withEmployees,
        employeesOptions,
        defaultEmployee,
        absenceData,
        setAbsenceData,
        day,
    } = props;

    const { planningStartOfWeek, steakers, siteSettingsEtab } = usePlanning();
    const [absenceDays, setAbsenceDays] = React.useState([
        {
            name: "Lun",
            index: 1,
            checked: false,
            day: moment(planningStartOfWeek).add(0, "day").format("YYYY-MM-DD"),
        },
        {
            name: "Mar",
            index: 2,
            checked: false,
            day: moment(planningStartOfWeek).add(1, "day").format("YYYY-MM-DD"),
        },
        {
            name: "Mer",
            index: 3,
            checked: false,
            day: moment(planningStartOfWeek).add(2, "day").format("YYYY-MM-DD"),
        },
        {
            name: "Jeu",
            index: 4,
            checked: false,
            day: moment(planningStartOfWeek).add(3, "day").format("YYYY-MM-DD"),
        },
        {
            name: "Ven",
            index: 5,
            checked: false,
            day: moment(planningStartOfWeek).add(4, "day").format("YYYY-MM-DD"),
        },
        {
            name: "Sam",
            index: 6,
            checked: false,
            day: moment(planningStartOfWeek).add(5, "day").format("YYYY-MM-DD"),
        },
        {
            name: "Dim",
            index: 7,
            checked: false,
            day: moment(planningStartOfWeek).add(6, "day").format("YYYY-MM-DD"),
        },
    ]);
    React.useEffect(() => {
        let order = moment(day).day();
        order = order === 0 ? 6 : order - 1;
        setAbsenceDays((prev: any) => {
            let updatedPrev = prev;
            updatedPrev[order].checked = true;
            return updatedPrev;
        });
        setAbsenceData((prev: any) => ({
            ...prev,
            days: [{ ...absenceDays[order] }],
        }));
    }, [day]);
    const [allTheDay, setallTheDay] = React.useState(true);
    React.useEffect(() => {
        if (absenceData?.from && absenceData?.to) {
            setallTheDay(
                absenceData?.from === "00:00" && absenceData?.to === "23:59",
            );
        }
    }, [absenceData?.from, absenceData?.to]);
    const handleEmployeeId = (value: any) => {
        setAbsenceData((prev: any) => ({ ...prev, employeeId: value }));
    };
    const AbsenceTimeInputs = () => {
        return (
            <div
                className="add-shift-time-container"
                style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "start",
                    flexWrap: "wrap",
                    gap: 16,
                    marginBottom: 18,
                    // marginTop: 34,
                }}
            >
                <Input
                    type="time"
                    captionText={"Début"}
                    defaultValue={absenceData.from}
                    onBlur={(value: any) =>
                        setAbsenceData((prev: any) => ({
                            ...prev,
                            from: value,
                        }))
                    }
                    inputWidth="110px"
                    borderColor="#C7CBE2"
                    captionColor="#2a346d"
                    borderWidth={1}
                    captionSize="12px"
                    // iconImg={Clock}
                    inputRadius="4px"
                    textColor="#4C5690"
                    textSize={14}
                    iconLocation="right"
                    style={{
                        inputStyle: {
                            minHeight: "48px",
                            fontWeight: "400",
                        },
                        captionStyle: {
                            fontWeight: "500",
                        },
                    }}
                />
                <Input
                    type="time"
                    captionText={"Fin"}
                    defaultValue={absenceData.to}
                    onBlur={(value: any) =>
                        setAbsenceData((prev: any) => ({
                            ...prev,
                            to: value,
                        }))
                    }
                    inputWidth="110px"
                    borderColor="#C7CBE2"
                    captionColor="#2a346d"
                    borderWidth={1}
                    captionSize="12px"
                    inputRadius="4px"
                    textColor="#4C5690"
                    textSize={14}
                    iconLocation="right"
                    style={{
                        inputStyle: {
                            minHeight: "48px",
                            fontWeight: "400",
                        },
                        captionStyle: {
                            fontWeight: "500",
                        },
                    }}
                />
                {/* <Input
                    captionText={"Pause (minutes)"}
                    inputWidth='90px'
                    borderColor='#C7CBE2'
                    captionColor='#C7CBE2'
                    borderWidth={1}
                    captionSize='12px'
                    inputRadius='4px'
                    textColor='#4C5690'
                    textSize={14}
                    type='number'
                    value={absenceData.pause}
                    onChange={(value: any) =>
                        setAbsenceData((prev: any) => ({
                            ...prev,
                            pause: parseFloat(value),
                        }))
                    }
                    style={{
                        inputStyle: {
                            minHeight: "48px",
                            fontWeight: "400",
                        },
                        captionStyle: {
                            fontWeight: "500",
                            color: "#8E96C1",
                        },
                    }}
                />
                <SelectInput
                    title='Repas'
                    options={[
                        { label: "0", value: 0 },
                        { label: "1", value: 1 },
                        { label: "2", value: 2 },
                        { label: "3", value: 3 },
                        { label: "4", value: 4 },
                    ]}
                    selectedValue={{
                        label: absenceData.repas,
                        value: parseInt(absenceData.repas),
                    }}
                    onSelectOption={(option) =>
                        setAbsenceData((prev: any) => ({
                            ...prev,
                            repas: option.label,
                        }))
                    }
                    style={{
                        height: "48px",
                        color: "#C7CBE2",
                    }}
                    captionColor='#C7CBE2'
                    captionSize='12px'
                    optionColor='#4C5690'
                    optionSize='14px'
                /> */}
            </div>
        );
    };
    let absenceTypes = [
        { label: "Évènement familial", value: "Évènement familial" },
        {
            label: "Indisponibilité ponctuelle",
            value: "Indisponibilité ponctuelle",
        },
        { label: "Repos hebdomadaire", value: "Repos hebdomadaire" },
        { label: "Absence injustifiée", value: "Absence injustifiée" },
        { label: "Absence justifiée", value: "Absence justifiée" },
        { label: "Accident du travail", value: "Accident du travail" },
        // { label: "Arrêt maladie", value: "Arrêt maladie" },
        { label: "Chômage technique", value: "Chômage technique" },
        { label: "Formation", value: "Formation" },
        // { label: "Jour férié", value: "Jour férié" },
        { label: "Maladie professionnelle", value: "Maladie professionnelle" },
        {
            label: "Mise à pied conservatoire",
            value: "Mise à pied conservatoire",
        },
        {
            label: "Mise à pied disciplinaire",
            value: "Mise à pied disciplinaire",
        },
        {
            label: "Repos compensateur d'habillement",
            value: "Repos compensateur d'habillement",
        },
        {
            label: "Repos compensateur de nuit",
            value: "Repos compensateur de nuit",
        },
        { label: "Visite médicale", value: "Visite médicale" },
        {
            label: "Réduction du temps de travail (RTT)",
            value: "Réduction du temps de travail (RTT)",
        },
    ];
    const [selectedabsenceType, setSelectedAbsenceType] = React.useState({
        label: "Repos hebdomadaire",
        value: "Repos hebdomadaire",
    });
    return (
        <div className="add-absence-container">
            {withEmployees && (
                <div className="emp-selection-container">
                    <SelectInput
                        title="Employés"
                        captionColor="#2a346d"
                        options={employeesOptions}
                        selectedValue={employeesOptions.find(
                            (option: any) =>
                                option.value === defaultEmployee._id,
                        )}
                        onSelectOption={(option) =>
                            handleEmployeeId(option.value)
                        }
                    ></SelectInput>
                </div>
            )}

            <div className="absence-type-container">
                <SelectInput
                    title="Type d'absence"
                    captionColor="#2a346d"
                    options={absenceTypes.sort((a, b) => {
                        const labelA = a.label
                            .normalize("NFD")
                            .replace(/[\u0300-\u036f]/g, "")
                            .toUpperCase();
                        const labelB = b.label
                            .normalize("NFD")
                            .replace(/[\u0300-\u036f]/g, "")
                            .toUpperCase();

                        return labelA.localeCompare(labelB, undefined, {
                            sensitivity: "accent",
                        });
                    })}
                    selectedValue={{
                        label: absenceData.typeAbsence,
                        value: absenceData.typeAbsence,
                    }}
                    onSelectOption={(option) =>
                        setAbsenceData((prev: any) => ({
                            ...prev,
                            typeAbsence: option.value,
                        }))
                    }
                ></SelectInput>
            </div>
            <div
                className="toggle-allday-container"
                style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                }}
            >
                <TextData
                    variant="div"
                    width="auto"
                    size="12px"
                    fontWeight="500"
                    color="#2a346d"
                >
                    Toute la journée
                </TextData>
                <ToggleButton
                    isChecked={allTheDay}
                    onClick={() => {
                        if (!allTheDay) {
                            setAbsenceData((data: any) => ({
                                ...data,
                                from: "00:00",
                                to: "23:59",
                                secondStart: "",
                                secondEnd: "",
                            }));
                        } else {
                            setAbsenceData((data: any) => ({
                                ...data,
                                from: "08:00",
                                to: "12:00",
                                secondStart: "",
                                secondEnd: "",
                            }));
                        }
                    }}
                />
            </div>
            {!allTheDay && (
                <AbsenceTimeInputs />

                // <div
                //     className='absence-time-container'
                //     style={{
                //         display: "flex",
                //         flexDirection: "row",
                //         justifyContent: "start",
                //         flexWrap: "wrap",
                //         gap: 32,
                //     }}
                // >
                //     <Input
                //         type='time'
                //         captionText={"Début"}
                //         value={absenceData.from}
                //         inputWidth='200px'
                //         borderColor='#C7CBE2'
                //         captionColor='#C7CBE2'
                //         borderWidth={1}
                //         captionSize='12px'
                //         // iconImg={Clock}
                //         inputRadius='4px'
                //         textColor='#4C5690'
                //         textSize={14}
                //         iconLocation='right'
                //         style={{
                //             inputStyle: {
                //                 minHeight: "48px",
                //                 fontWeight: "400",
                //             },
                //             captionStyle: {
                //                 fontWeight: "500",
                //             },
                //         }}
                //     />
                //     <Input
                //         type='time'
                //         captionText={"Fin"}
                //         value={absenceData.to}
                //         inputWidth='200px'
                //         borderColor='#C7CBE2'
                //         captionColor='#C7CBE2'
                //         borderWidth={1}
                //         captionSize='12px'
                //         // iconImg={Clock}
                //         inputRadius='4px'
                //         textColor='#4C5690'
                //         textSize={14}
                //         iconLocation='right'
                //         style={{
                //             inputStyle: {
                //                 minHeight: "48px",
                //                 fontWeight: "400",
                //             },
                //             captionStyle: {
                //                 fontWeight: "500",
                //             },
                //         }}
                //     />
                // </div>
            )}
            <div className="repeat-shifts-container">
                <TextData
                    width="auto"
                    size="12px"
                    fontWeight="500"
                    color="#2a346d"
                    style={{
                        marginTop: 35,
                        marginBottom: 16,
                    }}
                >
                    Répéter ce shift sur plusieurs jours
                </TextData>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        flexWrap: "nowrap",
                        gap: 19,
                    }}
                >
                    {absenceDays.map(({ name, index, checked, day }) => {
                        return (
                            <CircledRadio
                                id={index}
                                key={index}
                                thisDay={day}
                                text={name}
                                selected={checked}
                                onClick={(id, checkedValue) => {
                                    setAbsenceData((prev: any) => {
                                        let exist = false;
                                        let updatedData = prev.days.map(
                                            (day: any) => {
                                                if (day.index === id) {
                                                    exist = true;
                                                    return {
                                                        ...day,
                                                        checked: checkedValue,
                                                    };
                                                } else {
                                                    return day;
                                                }
                                            },
                                        );
                                        if (!exist) {
                                            updatedData.push({
                                                name,
                                                index,
                                                checked: checkedValue,
                                                day,
                                            });
                                        }
                                        return { ...prev, days: updatedData };
                                    });
                                }}
                            />
                        );
                    })}
                </div>
            </div>
            <div className="absence-note-container">
                <Input
                    captionText="Notes"
                    captionColor="#2a346d"
                    placeholder="Ecrire ici..."
                    // value='Ecrire ici...'
                    inputWidth="100%"
                    captionSize="12px"
                    inputRadius="4px"
                    textColor="#4C5690"
                    textSize={14}
                    iconLocation="right"
                    borderColor="#C7CBE2"
                    borderWidth={1}
                    style={{
                        inputStyle: {
                            minHeight: "48px",
                            fontWeight: "400",
                        },
                        captionStyle: {
                            fontWeight: "500",
                        },
                    }}
                />
            </div>
        </div>
    );
}

export default function AttendanceCard(props: any /*AttendanceCardProps*/) {
    const {
        display,
        isShiftEditing,
        setIsShiftEditing,
        isShift,
        setIsShift,
        toEdit,
        setShiftToEdit,
        onClose,
        withEmployees,
        defaultEmployee,
        day,
        dayOrder,
        department,
        startTime,
    } = props;

    const {
        addnewShift,
        deleteShift,
        editShift,
        handleResetError,
        employeesData,
    } = usePlanning();
    const [toggleTab, setToggleTab] = React.useState(true);
    const [shiftData, setShiftData] = React.useState<any>(null);
    const [absenceData, setAbsenceData] = React.useState<any>(null);
    React.useEffect(() => {
        if (startTime) {
            let endTime = startTime + 240;
            setShiftData((prev: any) => ({
                ...prev,
                from: fomatStartEndTime(startTime),
                to: fomatStartEndTime(endTime),
            }));
        }
    }, [startTime]);
    React.useEffect(() => {
        if (isShiftEditing && toEdit) {
            console.log("shift = ", toEdit);
            if (isShift) {
                setShiftData({
                    ...toEdit,
                    site: toEdit.site._id,
                    from: fomatStartEndTime(toEdit.from),
                    to: fomatStartEndTime(toEdit.to),
                    steaker: toEdit.steaker?._id || "",
                    planId: toEdit._id,
                    employeeId: toEdit.employee,
                    week: toEdit.week,
                    year: toEdit.year,
                    department: toEdit.department,
                });
            } else {
                setAbsenceData({
                    ...toEdit,
                    site: toEdit.site._id,
                    from: fomatStartEndTime(toEdit.from),
                    to: fomatStartEndTime(toEdit.to),
                    planId: toEdit._id,
                    employeeId: toEdit.employee,
                    week: toEdit.week,
                    year: toEdit.year,
                    department: toEdit.department,
                });
            }
        }
    }, [isShiftEditing, isShift, toEdit]);
    const handleAddShift = () => {
        addnewShift(shiftData);
        handleClose();
    };
    const handleModifyShift = () => {
        editShift(toEdit.type === 1 ? shiftData : absenceData);
        handleClose();
    };
    // const handleDeleteShift = () => {
    //     deleteShift(toEdit._id);
    //     handleClose();
    // };
    const handleDeleteShift = async () => {
        const confirmation = await Swal.fire({
            title: "Etes-vous sûr de vouloir supprimer ce shift?",
            // text: "This action cannot be undone!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6", // Customize button colors if desired
            cancelButtonColor: "#d33",
            confirmButtonText: "Oui, supprimer",
            cancelButtonText: "Annuler",
        });

        if (confirmation.isConfirmed) {
            deleteShift(toEdit._id); // Delete shift only if confirmed
            handleClose();
        }
    };

    // Swal.fire({
    //     title: "Succés!",
    //     text: "Votre modéle a été modifier avec succés!",
    //     icon: "success",
    // });
    // Swal.fire({
    //     title: "Erreur!",
    //     text: "Une erreur au niveau serveur!",
    //     icon: "error",
    //     confirmButtonText: "Ok",
    // });
    const handleAddAbsence = () => {
        addnewShift(absenceData);
        handleClose();
    };

    /*
       const handleDeleteFixedShift = () => {
        deleteFixedShift(editThisShift._id);
        handleClose();
    };
    const handleModifyFixedShift = () => {
        editFixedShift(
            editThisShift.type === 1 ? shiftFixedData : absenceFixedData
        );
        handleClose();
    };
    
    */

    const [employeesOptions, setEmployeesOptions] = React.useState([
        {
            label: (
                <TextData
                    width="auto"
                    fontWeight="600"
                    color="#101844"
                    size="12px"
                >
                    Aucun employee
                </TextData>
            ),
            value: "",
        },
    ]);
    React.useEffect(() => {
        let newEmps: any = [];
        let emps: any = Object.values(employeesData);
        for (let i = 0; i < emps?.length; i++) {
            const { fname, lname, _id, picture, role } = emps[i];
            const pictureProps = {
                imgPath: ROUTER.STATIC_IMG(picture),
                width: "40px",
                height: "40px",
                borderRadius: "50%",
                style: { borderRadius: "50%" },
            };
            const fullName = fname + " " + lname;
            newEmps.push({
                label: (
                    <TextData
                        width="auto"
                        fontWeight="600"
                        color="#101844"
                        size="13px"
                        style={{ marginLeft: "5px" }}
                    >
                        {/* {fullName?.slice(0, 5)} {fullName.length > 15 && "..."} */}
                        {fullName}
                    </TextData>
                ),
                value: _id,
                icon: (
                    <ProfilePicture
                        profileImage={picture}
                        firstName={fname}
                        lastName={lname}
                        pictureProps={pictureProps}
                        altColor={employeeRoleColor(role)}
                    />
                ),
            });
        }
        setEmployeesOptions(newEmps);
    }, [employeesData]);

    const handleClose = () => {
        onClose && onClose();
    };
    React.useEffect(() => {
        if (display === false) {
            setShiftData({
                employeeId: defaultEmployee?._id || "",
                day,
                from: "08:00",
                to: "12:00",
                pause: 0,
                type: 1,
                typeAbsence: "",
                comment: "",
                days: [],
                steaker: "",
                repas: "0",
                secondStart: "",
                secondEnd: "",
                department: department?._id || null,
            });
            setAbsenceData({
                employeeId: defaultEmployee?._id || "",
                day,
                from: "00:00",
                to: "23:59",
                pause: 0,
                type: 0,
                typeAbsence: "Repos hebdomadaire",
                comment: "",
                days: [],
                steaker: "",
                repas: "0",
                secondStart: "",
                secondEnd: "",
                department: department?._id || null,
            });
            handleResetError();
            setIsShiftEditing?.(false);
            setShiftToEdit?.(null);
            setToggleTab(true);
        }
    }, [display]);
    const CardEditHeader = () => {
        return (
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    margin: "25px auto 25px 40px",
                    width: "100%",
                }}
            >
                <TextData
                    variant="label"
                    width="auto"
                    size="22px"
                    fontWeight="700"
                    color="#2A346D"
                >
                    Modifier {toEdit.type === 1 ? "le shift" : "l'absence"}
                </TextData>
            </div>
        );
    };
    return (
        <Modal modalEnabled={display} onClose={handleClose}>
            <div className="attendance-container">
                <PopUpCard
                    containerWidth="620px"
                    display={display}
                    onClose={handleClose}
                    isCloseIcon={toEdit}
                    header={
                        isShiftEditing ? (
                            <CardEditHeader />
                        ) : (
                            <CardHeader
                                setToggleTab={setToggleTab}
                                toggleTab={toggleTab}
                            />
                        )
                    }
                    popupHeaderStyle={{
                        backgroundColor: "#fff",
                        borderBottom: "1px solid #EDEFF5",
                        overflow: "hidden",
                    }}
                    widthCloseIcon="24px"
                    styleCloseIcon={{
                        padding: "15px",
                        marginRight: "25px",
                        height: "auto",
                        width: "auto",
                        borderTopRightRadius: "16px",
                    }}
                    button1Text="Annuler"
                    onClickButton1={handleClose}
                    button2Text={isShiftEditing ? "Supprimer" : "Ajouter"}
                    onClickButton2={
                        isShiftEditing
                            ? handleDeleteShift
                            : toggleTab
                              ? handleAddShift
                              : handleAddAbsence
                    }
                    button2Style={
                        isShiftEditing
                            ? {
                                  backgroundColor: "#eb2f06",
                                  color: "white",
                                  fontSize: "14px",
                              }
                            : undefined
                    }
                    button3Text={isShiftEditing ? "Modifier" : undefined}
                    onClickButton3={
                        isShiftEditing ? handleModifyShift : undefined
                    }
                >
                    {isShiftEditing ? (
                        <div
                            style={{
                                maxHeight: "75vh",
                                overflow: "auto",
                            }}
                        >
                            {toEdit.type === 1 ? (
                                <AddShift
                                    withEmployees={withEmployees}
                                    employeesOptions={employeesOptions}
                                    defaultEmployee={defaultEmployee}
                                    shiftData={shiftData}
                                    setShiftData={setShiftData}
                                    dayOrder={dayOrder}
                                    isShiftEditing={isShiftEditing}
                                    day={day}
                                />
                            ) : (
                                <AddAbsence
                                    withEmployees={withEmployees}
                                    employeesOptions={employeesOptions}
                                    defaultEmployee={defaultEmployee}
                                    absenceData={absenceData}
                                    setAbsenceData={setAbsenceData}
                                    dayOrder={dayOrder}
                                    isShiftEditing={isShiftEditing}
                                    day={day}
                                />
                            )}
                        </div>
                    ) : (
                        <div
                            style={{
                                maxHeight: "75vh",
                                overflow: "auto",
                            }}
                        >
                            {toggleTab ? (
                                <AddShift
                                    withEmployees={withEmployees}
                                    employeesOptions={employeesOptions}
                                    defaultEmployee={defaultEmployee}
                                    shiftData={shiftData}
                                    setShiftData={setShiftData}
                                    dayOrder={dayOrder}
                                    isShiftEditing={isShiftEditing}
                                    day={day}
                                />
                            ) : (
                                <AddAbsence
                                    withEmployees={withEmployees}
                                    employeesOptions={employeesOptions}
                                    defaultEmployee={defaultEmployee}
                                    absenceData={absenceData}
                                    setAbsenceData={setAbsenceData}
                                    dayOrder={dayOrder}
                                    isShiftEditing={isShiftEditing}
                                    day={day}
                                />
                            )}
                        </div>
                    )}
                </PopUpCard>
            </div>
        </Modal>
    );
}
const CardHeader = (props: any) => {
    const { setToggleTab, toggleTab } = props;
    return (
        <div
            style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
            }}
        >
            <Button
                backgroundColor={toggleTab ? "#2A8BAB" : "#EDEFF5"}
                height="56px"
                width="50%"
                style={{ borderRadius: 0, borderTopLeftRadius: "16px" }}
                onClick={() => {
                    setToggleTab(true);
                }}
            >
                <TextData
                    variant="label"
                    width="auto"
                    color={toggleTab ? "#FFFFFF" : "#7f839c"}
                    fontWeight={toggleTab ? "700" : "400"}
                    size="14px"
                    style={{
                        cursor: "pointer",
                    }}
                >
                    Ajouter un shift
                </TextData>
            </Button>
            <Button
                backgroundColor={!toggleTab ? "#2A8BAB" : "#EDEFF5"}
                height="56px"
                width="50%"
                style={{ borderRadius: 0 }}
                onClick={() => {
                    setToggleTab(false);
                }}
            >
                <TextData
                    variant="label"
                    width="auto"
                    color={!toggleTab ? "#FFFFFF" : "#7f839c"}
                    fontWeight={!toggleTab ? "700" : "400"}
                    size="14px"
                    style={{
                        cursor: "pointer",
                    }}
                >
                    Ajouter une absence
                </TextData>
            </Button>
        </div>
    );
};
// const CardHeader = () => {
//     return (
//         <div
//             style={{
//                 width: "100%",
//                 display: "flex",
//                 flexDirection: "row",
//             }}
//         >
//             <Button
//                 backgroundColor={toggleTab ? "#2A8BAB" : "#EDEFF5"}
//                 height="56px"
//                 width="50%"
//                 style={{ borderRadius: 0, borderTopLeftRadius: "16px" }}
//                 onClick={() => {
//                     setToggleTab(true);
//                 }}
//             >
//                 <TextData
//                     variant="label"
//                     width="auto"
//                     color={toggleTab ? "#FFFFFF" : "#7f839c"}
//                     fontWeight={toggleTab ? "700" : "400"}
//                     size="14px"
//                     style={{
//                         cursor: "pointer",
//                     }}
//                 >
//                     Ajouter un shift
//                 </TextData>
//             </Button>
//             <Button
//                 backgroundColor={!toggleTab ? "#2A8BAB" : "#EDEFF5"}
//                 height="56px"
//                 width="50%"
//                 style={{ borderRadius: 0 }}
//                 onClick={() => {
//                     setToggleTab(false);
//                 }}
//             >
//                 <TextData
//                     variant="label"
//                     width="auto"
//                     color={!toggleTab ? "#FFFFFF" : "#7f839c"}
//                     fontWeight={!toggleTab ? "700" : "400"}
//                     size="14px"
//                     style={{
//                         cursor: "pointer",
//                     }}
//                 >
//                     Ajouter une absence
//                 </TextData>
//             </Button>
//         </div>
//     );
// };

interface AttendanceCardProps {
    display?: boolean;
    isShiftEditing?: boolean;
    setIsShiftEditing?: any;
    isShift?: boolean;
    setIsShift?: any;
    toEdit?: any;
    setShiftToEdit?: any;
    onClose?: (event?: Event) => void;
    withEmployees: boolean;
    employees?: any;
    defaultEmployee?: any;
    day?: string;
    dayOrder?: number;
}

AttendanceCard.defaultProps = {
    day: moment().format("YYYY-MM-DD"),
    display: true,
    onClose: () => {
        console.log("onClose");
    },
    withEmployees: true,
    dayOrder: 0,
};
