import React from "react";
import "./style.scss";
import moment from "moment";
import CellEmpty from "../../molecules/CellEmpty";
import PlanningDayCell from "../../molecules/PlanningDayCell";
import { usePlanning } from "../../../hooks/usePlanning";
import AttendanceCard from "../../organism/AttendanceCard";
import { ReactComponent as CongeSVG } from "./conge.svg";
import { ReactComponent as IndispoSVG } from "./no-work.svg";

const congeTypes = [
    { label: "Arrêt maladie", value: "arret_maladie" },
    { label: "Congé maternité", value: "conge_maternite" },
    { label: "Congé paternité", value: "conge_paternite" },
    { label: "Congé payé", value: "paid_leave" },
    { label: "Congé sans solde", value: "unpaid_leave" },
    { label: "Évènement familial", value: "family_event" },
];
export default function RenderDayCell(props: any) {
    const memoizedProps = React.useMemo(() => props, [props]);

    const {
        day,
        plansData,
        defaultEmployee,
        department,
        published,
        numberOfHourCellsInDay,
        startCellHour,
        dayOrder,
    } = memoizedProps;
    const {
        copyShift,
        planningStartOfWeek,
        validCongesByUser,
        planningDisponibility,
    } = usePlanning();
    const conge = React.useMemo(() => {
        return validCongesByUser?.[defaultEmployee._id]?.[day];
    }, [day, defaultEmployee, validCongesByUser]);
    const disponibility = React.useMemo(() => {
        return planningDisponibility?.[defaultEmployee?._id]?.[
            (moment(day).day() + 6) % 7
        ];
    }, [day, defaultEmployee, planningDisponibility]);
    const [shiftDays, setShiftDays] = React.useState([
        {
            name: "Lun",
            index: 1,
            checked: false,
            day: moment(planningStartOfWeek).add(0, "day").format("YYYY-MM-DD"),
        },
        {
            name: "Mar",
            index: 2,
            checked: false,
            day: moment(planningStartOfWeek).add(1, "day").format("YYYY-MM-DD"),
        },
        {
            name: "Mer",
            index: 3,
            checked: false,
            day: moment(planningStartOfWeek).add(2, "day").format("YYYY-MM-DD"),
        },
        {
            name: "Jeu",
            index: 4,
            checked: false,
            day: moment(planningStartOfWeek).add(3, "day").format("YYYY-MM-DD"),
        },
        {
            name: "Ven",
            index: 5,
            checked: false,
            day: moment(planningStartOfWeek).add(4, "day").format("YYYY-MM-DD"),
        },
        {
            name: "Sam",
            index: 6,
            checked: false,
            day: moment(planningStartOfWeek).add(5, "day").format("YYYY-MM-DD"),
        },
        {
            name: "Dim",
            index: 7,
            checked: false,
            day: moment(planningStartOfWeek).add(6, "day").format("YYYY-MM-DD"),
        },
    ]);

    const shiftData = plansData?.find((plan: any) => plan.day === day);
    const [isDraggingOver, setIsDraggingOver] = React.useState(false);
    const [isDraggingOverCopy, setIsDraggingOverCopy] = React.useState(false);
    const [isDraggingOverMove, setIsDraggingOverMove] = React.useState(false);
    const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        setIsDraggingOver(true); // Set dragging over state
    };
    const handleDragLeave = () => {
        setIsDraggingOver(false); // Reset dragging over state
    };
    const handleDragOverCopy = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        setIsDraggingOverCopy(true); // Set dragging over state
    };
    const handleDragLeaveCopy = () => {
        setIsDraggingOverCopy(false); // Reset dragging over state
    };
    const handleDragOverMove = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        setIsDraggingOverMove(true); // Set dragging over state
    };
    const handleDragLeaveMove = () => {
        setIsDraggingOverMove(false); // Reset dragging over state
    };
    const handleDropCopy = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        const shiftData = JSON.parse(event.dataTransfer.getData("text/plain"));
        const { shift, type } = shiftData;
        if (shift && type) {
            copyShift(
                {
                    shiftId: shift._id,
                    employeeId: defaultEmployee?._id,
                    day,
                    department: department?._id || null,
                },
                "copy",
            );
        }
        setIsDraggingOverCopy(false);
        setIsDraggingOver(false);
    };
    const handleDropMove = async (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        const shiftData = JSON.parse(event.dataTransfer.getData("text/plain"));
        const { shift, type } = shiftData;
        if (shift && type) {
            copyShift(
                {
                    shiftId: shift._id,
                    employeeId: defaultEmployee?._id,
                    day,
                    department: department._id || undefined,
                },
                "move",
            );
        }
        setIsDraggingOverMove(false);
        setIsDraggingOver(false);
    };
    const { setDraggedShiftType, draggedShiftType, employeesData } =
        usePlanning();
    const handleDragStart = (
        event: React.DragEvent<HTMLDivElement>,
        data: any,
    ) => {
        // Set the shift data to be transferred during drag
        setDraggedShiftType("normal");
        event.dataTransfer.setData(
            "text/plain",
            JSON.stringify({ type: "normal", shift: data }),
        );
    };
    const [isClicked, setIsClicked] = React.useState(false);
    const [startTime, setStartTime] = React.useState("");
    return (
        <th colSpan={7} style={{ height: "inherit" }}>
            <div
                className="cell-day-parent"
                onDragOver={handleDragOver}
                onDragLeave={handleDragLeave}
                style={{
                    position: "relative",
                    height: "100%",
                }}
            >
                <div
                    style={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                        zIndex: "9",
                        gap: "3px",
                    }}
                >
                    {shiftData?.workShares?.length >= 0 && (
                        <div
                            style={{
                                position: "relative",
                                height: "80px",
                            }}
                        >
                            {isDraggingOver ? (
                                <div
                                    style={{
                                        width: "100%",
                                        display: "flex",
                                        gap: "10px",
                                        padding: "10px",
                                        position: "absolute",
                                        height: "100%",
                                        zIndex: "9",
                                    }}
                                >
                                    <div
                                        onDragOver={handleDragOverCopy}
                                        onDragLeave={handleDragLeaveCopy}
                                        onDrop={handleDropCopy}
                                        style={{
                                            padding: "10px",
                                            borderRadius: "5px",
                                            border: "1px dashed #6f78ab",
                                            width:
                                                draggedShiftType === "normal"
                                                    ? "50%"
                                                    : "100%",
                                            height: "100%",
                                            marginBottom: "2px",
                                            color: isDraggingOverCopy
                                                ? "white"
                                                : "#6f78ab",
                                            backgroundColor: isDraggingOverCopy
                                                ? "#2a8bab"
                                                : "",
                                        }}
                                    >
                                        Copier
                                    </div>
                                    {draggedShiftType === "normal" && (
                                        <div
                                            onDragOver={handleDragOverMove}
                                            onDragLeave={handleDragLeaveMove}
                                            onDrop={handleDropMove}
                                            style={{
                                                padding: "10px",
                                                borderRadius: "5px",
                                                border: "1px dashed #6f78ab",
                                                width: "50%",
                                                height: "100%",
                                                color: isDraggingOverMove
                                                    ? "white"
                                                    : "#6f78ab",
                                                backgroundColor:
                                                    isDraggingOverMove
                                                        ? "#2a8bab"
                                                        : "",
                                            }}
                                        >
                                            Déplacer
                                        </div>
                                    )}
                                </div>
                            ) : (
                                shiftData?.workShares?.length > 0 && (
                                    <>
                                        {shiftData?.workShares?.map(
                                            (data: any) => {
                                                return (
                                                    <div
                                                        draggable
                                                        onDragStart={(e) =>
                                                            handleDragStart(
                                                                e,
                                                                data,
                                                            )
                                                        }
                                                        style={{
                                                            position:
                                                                "absolute",
                                                            top: "3px",
                                                            left:
                                                                data.type === 0
                                                                    ? "0"
                                                                    : `calc(calc(calc(100% / ${
                                                                          4 *
                                                                          numberOfHourCellsInDay
                                                                      }) * ${
                                                                          (data?.from -
                                                                              startCellHour *
                                                                                  60 +
                                                                              30) /
                                                                          15
                                                                      }) - 1px)`,
                                                            width:
                                                                data.type === 0
                                                                    ? "100%"
                                                                    : `calc(calc(calc(100% / ${
                                                                          4 *
                                                                          numberOfHourCellsInDay
                                                                      }) * ${
                                                                          data?.to >
                                                                          data?.from
                                                                              ? (data?.to -
                                                                                    data?.from) /
                                                                                15
                                                                              : (data?.to +
                                                                                    1440 -
                                                                                    data?.from) /
                                                                                15
                                                                      }) - 0px)`,
                                                            height: "calc(100% - 6px)",
                                                            zIndex: "9",
                                                        }}
                                                    >
                                                        <PlanningDayCell
                                                            data={data}
                                                            planningEmployees={Object.values(
                                                                employeesData,
                                                            )}
                                                            published={
                                                                published
                                                            }
                                                            // disponibility={
                                                            //     planning.disponibility[defaultEmployee._id][
                                                            //         i
                                                            //     ]
                                                            // }
                                                            defaultEmployee={
                                                                defaultEmployee
                                                            }
                                                            department={
                                                                department
                                                            }
                                                        />
                                                    </div>
                                                );
                                            },
                                        )}
                                    </>
                                )
                            )}
                        </div>
                    )}

                    {disponibility && (
                        <div
                            className="indisponibility-cell"
                            style={{
                                borderColor: "#1D1B91",
                                backgroundColor: "#817FDD",
                                height: "50px",
                                gap: "10px",
                                borderWidth: "2px",
                            }}
                        >
                            <div className="icon">
                                <IndispoSVG
                                    color="#1D1B91"
                                    width={25}
                                    height={25}
                                />
                            </div>
                            <div
                                className="text"
                                style={{
                                    color: "#1D1B91",
                                }}
                            >
                                <div
                                    style={{
                                        fontWeight: "bold",
                                        fontSize: "16px",
                                    }}
                                >
                                    Indisponible
                                </div>
                                <div>
                                    de {disponibility.startHour} à{" "}
                                    {disponibility.endHour}
                                </div>
                            </div>
                        </div>
                    )}
                    {conge && (
                        <div
                            className="indisponibility-cell"
                            style={{
                                borderColor: "#0F556D",
                                backgroundColor: "#7EC3DB",
                                height: "50px",
                                gap: "10px",
                                borderWidth: "2px",
                            }}
                        >
                            <div className="icon">
                                <CongeSVG
                                    color="#0F556D"
                                    width={25}
                                    height={25}
                                />
                            </div>
                            <div
                                className="text"
                                style={{
                                    color: "#0F556D",
                                    fontSize: "16px",
                                }}
                            >
                                <div
                                    style={{
                                        fontWeight: "bold",
                                    }}
                                >
                                    {
                                        congeTypes?.find(
                                            (congeType: any) =>
                                                congeType.value === conge?.type,
                                        )?.label
                                    }
                                </div>
                                <div
                                    style={{
                                        fontWeight: "normal",
                                        fontSize: "14px",
                                    }}
                                >
                                    Toute la journée
                                </div>
                            </div>
                        </div>
                    )}
                </div>
                <div
                    style={{
                        position: "absolute",
                        width: "100%",
                        height: "100%",
                        // zIndex: "9999999",
                        // backgroundColor: "pink",
                        display: "flex",
                    }}
                >
                    {Array(numberOfHourCellsInDay * 2)
                        .fill(0)
                        .map((item, index) => (
                            <div
                                className="cell-day-child"
                                style={{
                                    width: `calc(100% / ${
                                        numberOfHourCellsInDay * 2
                                    })`,
                                    borderRight:
                                        index % 2 === 0 &&
                                        index !== numberOfHourCellsInDay * 2 - 1
                                            ? "1px solid #c7cbe2ac"
                                            : index !==
                                                numberOfHourCellsInDay * 2 - 1
                                              ? "1px solid #edeff5"
                                              : "none",
                                }}
                            >
                                <span
                                    style={{
                                        fontSize: "8px",
                                    }}
                                ></span>
                                <CellEmpty
                                    setIsClicked={setIsClicked}
                                    published={published}
                                    isWeek={false}
                                    time={(startCellHour + index / 2) * 60 - 30}
                                    setStartTime={setStartTime}
                                    style={{
                                        height: "100%",
                                    }}
                                />
                            </div>
                        ))}
                </div>
            </div>
            {isClicked && (
                <AttendanceCard
                    display={isClicked}
                    onClose={() => setIsClicked(false)}
                    defaultEmployee={defaultEmployee}
                    day={day}
                    dayOrder={dayOrder}
                    startTime={startTime}
                    department={department}
                />
            )}
        </th>
    );
}
