import * as React from "react";

import "./style.scss";
import moment from "moment";
import PlanningEmployeeCell from "../../molecules/PlanningEmployeeCell";
import PlanningTableConfiguration from "../../molecules/PlanningTableConfiguration";
import PlanningTableFooter from "../../molecules/PlanningTableFooter";
import { usePlanning } from "../../../hooks/usePlanning";
import RenderDayCell from "./RenderDayCell";
import RenderCells from "./RenderCells";
import BannerTableHeader from "./BannerTableHeader";

export default function PlanningTableBody(
    props: any /*PlanningTableBodyProps*/,
) {
    console.log(" render the body");
    const memoizedProps = React.useMemo(() => {
        return props;
    }, [props]);
    const {
        period,
        openingTime,
        closingTime,
        totalTime,
        numberOfHourCellsInDay,
        selectedDay,
        startCellHour,
        handleOpenDuplicate,
        handleOpenEdit,
        handleOpenDelete,
        handleOpenDownload,
        handleOpenPublish,
        toggleOpenNote,
        setDayNumber,
        setDepartment,
        setIsEdit,
    } = memoizedProps;
    const {
        planningOptions,
        planningStartOfWeek,
        departmentsServices,
        week,
        departments,
        planningInfoDepartments,
        planningInfo,
        employeesPlanningData,
        services,
        toSearchPlanningEmployees,
        planningCustomSort,
        daysServices,
        printPlanning,
    } = usePlanning();

    const dayOrder = moment().isoWeekday();
    const weekNumber = moment().isoWeek();
    const [expandedDepartments, setExpandedDepartments] = React.useState(
        Array(departments.length + 1).fill(true),
    );

    const colors = [
        "#ff6633",
        "#8833ff",
        "#33bfff",
        "#15b905",
        "#ff9600",
        "#083544",
        "#673ab7",
        "#ea1e62",
        "#0ab199",
        "#5677fd",
    ];
    let withoutDepartment = -1;
    return (
        <tbody>
            {departments?.map((department: any, index: number) => {
                let published = false;
                if (
                    planningInfoDepartments &&
                    planningInfoDepartments[department?._id]
                ) {
                    published =
                        planningInfoDepartments[department?._id]?.published;
                }

                return (
                    <>
                        <BannerTableHeader
                            index={index}
                            name={department.name}
                            depId={department._id}
                            color={colors[index % 10]}
                            published={published}
                            expanded={expandedDepartments[index]}
                            setExpanded={setExpandedDepartments}
                            handleOpenEdit={handleOpenEdit}
                            handleOpenDelete={handleOpenDelete}
                            handleOpenDownload={handleOpenDownload}
                            handleOpenPublish={handleOpenPublish}
                            handleOpenDuplicate={handleOpenDuplicate}
                            printPlanning={printPlanning}
                            period={period}
                            departments={departments}
                        />
                        <PlanningTableConfiguration
                            isServices={services?.length > 0}
                            startOfWeek={planningStartOfWeek}
                            serviceValues={
                                departmentsServices[department._id] || []
                            }
                            toggleOpenNote={toggleOpenNote}
                            setDayNumber={setDayNumber}
                            setIsEdit={setIsEdit}
                            period={period}
                            openingTime={openingTime}
                            closingTime={closingTime}
                            numberOfHourCellsInDay={numberOfHourCellsInDay}
                            isMeteo={false}
                            isNotes={!planningOptions.hideNotes}
                            setDepartment={setDepartment}
                            department={department}
                            selectedDay={selectedDay}
                            startCellHour={startCellHour}
                            style={{
                                display: !expandedDepartments[index]
                                    ? "none"
                                    : "",
                            }}
                        />
                        {/* {department.employees
                            ?.filter(
                                (employeeID: any) =>
                                    planning.toSearchPlanningEmployees[
                                        employeeID
                                    ],
                            )
                            .map((employeeID: any, key: any) => {
                                const employee = planning.employeesData
                                    ? planning.employeesData[employeeID] ||
                                      undefined
                                    : undefined; */}
                        {planningCustomSort?.[department?._id]?.data
                            ?.filter(
                                (item: any) =>
                                    toSearchPlanningEmployees[item.employee] &&
                                    planningCustomSort?.[department?._id]
                                        ?.listOfEmp?.[item?.employee],
                            )
                            .map((item: any, key: any) => {
                                let employee =
                                    planningCustomSort?.[department?._id]
                                        ?.listOfEmp?.[item?.employee] ?? null;
                                //test
                                if (!employee) {
                                    return null;
                                }
                                const empPlansPerDay =
                                    employeesPlanningData?.employees &&
                                    employee?._id
                                        ? employeesPlanningData?.employees[
                                              employee?._id
                                          ]
                                        : null;
                                let workedTime = 0;
                                let planned = false;
                                let dayPlanned = false;
                                if (empPlansPerDay) {
                                    empPlansPerDay?.map((day: any) => {
                                        let timePerDay = 0;
                                        day.workShares.map((work: any) => {
                                            if (work.type === 1) {
                                                if (day.day === selectedDay) {
                                                    dayPlanned = true;
                                                }
                                                planned = true;
                                                timePerDay +=
                                                    work.to > work.from
                                                        ? work.to -
                                                          work.from -
                                                          work.pause
                                                        : 1440 -
                                                          work.from +
                                                          work.to -
                                                          work.pause;
                                            }
                                        });
                                        workedTime += timePerDay;
                                    });
                                }
                                if (
                                    planningOptions.hideNoPlansEmployees &&
                                    (!empPlansPerDay || !planned) &&
                                    period === "week"
                                ) {
                                    return null;
                                }
                                if (
                                    planningOptions.hidePlansEmployees &&
                                    empPlansPerDay &&
                                    planned &&
                                    period === "week"
                                ) {
                                    return null;
                                }
                                if (
                                    planningOptions.hideNoPlansEmployees &&
                                    period === "day" &&
                                    !dayPlanned
                                ) {
                                    return null;
                                }
                                if (
                                    planningOptions.hidePlansEmployees &&
                                    empPlansPerDay &&
                                    dayPlanned &&
                                    period === "day"
                                ) {
                                    return null;
                                }

                                return (
                                    <tr
                                        key={key}
                                        style={{
                                            height: "80px",
                                            maxHeight: "max-content",
                                            display: !expandedDepartments[index]
                                                ? "none"
                                                : "",
                                        }}
                                    >
                                        <th
                                            id="col-1"
                                            style={{
                                                width: "15%",
                                                height: "100%",
                                            }}
                                        >
                                            <PlanningEmployeeCell
                                                employee={employee}
                                                workedTime={workedTime}
                                            />
                                        </th>

                                        {period === "week" ? (
                                            <RenderCells
                                                data={empPlansPerDay}
                                                defaultEmployee={employee}
                                                published={published}
                                                weekNumber={weekNumber}
                                                dayOrder={dayOrder}
                                                department={department}
                                            />
                                        ) : (
                                            <RenderDayCell
                                                day={selectedDay}
                                                plansData={empPlansPerDay}
                                                defaultEmployee={employee}
                                                published={published}
                                                department={department}
                                                numberOfHourCellsInDay={
                                                    numberOfHourCellsInDay
                                                }
                                                startCellHour={startCellHour}
                                                dayOrder={dayOrder}
                                            />
                                        )}
                                    </tr>
                                );
                            })}
                        {planningOptions.hideTotalTimeDepartment === false &&
                            expandedDepartments[index] && (
                                <PlanningTableFooter
                                    selectedDay={selectedDay}
                                    week={week}
                                    period={period}
                                    department={department.name}
                                    totalTime={totalTime[department._id]}
                                />
                            )}
                    </>
                );
            })}
            {planningCustomSort?.["withoutDepartment"]?.data
                ?.filter(
                    (item: any) =>
                        toSearchPlanningEmployees[item.employee] &&
                        planningCustomSort?.["withoutDepartment"]?.listOfEmp?.[
                            item?.employee
                        ],
                )
                ?.map((item: any, key: any) => {
                    let employee =
                        planningCustomSort?.["withoutDepartment"]?.listOfEmp?.[
                            item?.employee
                        ] ?? null;
                    if (!employee) {
                        return null;
                    }
                    const empPlansPerDay =
                        employeesPlanningData?.employees && employee?._id
                            ? employeesPlanningData?.employees[employee?._id]
                            : null;
                    let workedTime = 0;
                    let planned = false;
                    let dayPlanned = false;
                    if (empPlansPerDay) {
                        empPlansPerDay?.map((day: any) => {
                            let timePerDay = 0;
                            day.workShares.map((work: any) => {
                                if (work.type === 1) {
                                    if (day.day === selectedDay) {
                                        dayPlanned = true;
                                    }
                                    planned = true;
                                    timePerDay +=
                                        work.to > work.from
                                            ? work.to - work.from - work.pause
                                            : 1440 -
                                              work.from +
                                              work.to -
                                              work.pause;
                                }
                            });
                            workedTime += timePerDay;
                        });
                    }
                    if (
                        (planningOptions.hideNoPlansEmployees &&
                            (!empPlansPerDay || !planned)) ||
                        !employee
                    ) {
                        return null;
                    }
                    if (
                        planningOptions.hidePlansEmployees &&
                        empPlansPerDay &&
                        planned &&
                        employee
                    ) {
                        return null;
                    }
                    withoutDepartment++;
                    return (
                        <>
                            {withoutDepartment === 0 && (
                                <>
                                    <BannerTableHeader
                                        index={departments?.length}
                                        name={
                                            departments?.length
                                                ? "Sans département"
                                                : ""
                                        }
                                        color="#6f78ab"
                                        published={
                                            departments?.length > 0
                                                ? true
                                                : planningInfo?.published
                                        }
                                        expanded={
                                            expandedDepartments[
                                                departments?.length
                                            ]
                                        }
                                        departments={departments}
                                        setExpanded={setExpandedDepartments}
                                        handleOpenDuplicate={
                                            handleOpenDuplicate
                                        }
                                        handleOpenEdit={handleOpenEdit}
                                        handleOpenDelete={handleOpenDelete}
                                        handleOpenDownload={handleOpenDownload}
                                        handleOpenPublish={handleOpenPublish}
                                        printPlanning={printPlanning}
                                        period={period}
                                    />
                                    {departments?.length === 0 && (
                                        <PlanningTableConfiguration
                                            isServices={services?.length > 0}
                                            startOfWeek={planningStartOfWeek}
                                            serviceValues={daysServices || []}
                                            toggleOpenNote={toggleOpenNote}
                                            setDayNumber={setDayNumber}
                                            setIsEdit={setIsEdit}
                                            period={period}
                                            openingTime={openingTime}
                                            closingTime={closingTime}
                                            numberOfHourCellsInDay={
                                                numberOfHourCellsInDay
                                            }
                                            isMeteo={false}
                                            isNotes={!planningOptions.hideNotes}
                                            setDepartment={setDepartment}
                                            department={undefined}
                                            selectedDay={selectedDay}
                                            startCellHour={startCellHour}
                                        />
                                    )}
                                </>
                            )}
                            <tr
                                key={key}
                                style={{
                                    height: "80px",
                                    maxHeight: "max-content",
                                    display: !expandedDepartments[
                                        departments?.length
                                    ]
                                        ? "none"
                                        : "",
                                }}
                            >
                                <th
                                    id="col-1"
                                    style={{
                                        width: "15%",
                                        height: "100%",
                                    }}
                                >
                                    <PlanningEmployeeCell
                                        employee={employee}
                                        workedTime={workedTime}
                                    />
                                </th>

                                {period === "week" ? (
                                    <RenderCells
                                        data={empPlansPerDay}
                                        defaultEmployee={employee}
                                        published={
                                            departments?.length > 0
                                                ? true
                                                : planningInfo?.published
                                        }
                                        weekNumber={weekNumber}
                                        dayOrder={dayOrder}
                                        department={null}
                                    />
                                ) : (
                                    <RenderDayCell
                                        day={selectedDay}
                                        plansData={empPlansPerDay}
                                        published={
                                            departments?.length > 0
                                                ? true
                                                : planningInfo?.published
                                        }
                                        defaultEmployee={employee}
                                        numberOfHourCellsInDay={
                                            numberOfHourCellsInDay
                                        }
                                        startCellHour={startCellHour}
                                        selectedDay={selectedDay}
                                        dayOrder={dayOrder}
                                    />
                                )}
                            </tr>
                        </>
                    );
                })}
        </tbody>
    );
}
interface ShiftProps {
    alert?: boolean;
    alertText?: string;
    date?: string;
    shiftData?: string[];
}
interface Employee {
    picture?: string;
    firstName?: string;
    lastName?: string;
    role?: string;
    contractHours?: number;
    actualWorkedHours?: number;
    remainingHours?: number;
    shifts?: ShiftProps[];
}
interface EmployeeService {
    name?: string;
    color?: string;
    configuration?: "readonly" | "edit";
    employees?: Employee[];
}
interface PlanningTableBodyProps {
    services: EmployeeService[] | null;
}
// PlanningTableBody.defaultProps = {
//     services: [
//         {
//             name: "Cuisine",
//             color: "#f39c12",
//             configuration: "readonly",
//             employees: [
//                 {
//                     picture: "",
//                     firstName: "",
//                     lastName: "",
//                     role: "",
//                     contractHours: 35,
//                     actualWorkedHours: 5,
//                     remainingHours: 30,
//                     shifts: [
//                         {
//                             alert: false,
//                             date: "",
//                             shiftData: ["test1", "test2", "test3"],
//                         },
//                     ],
//                 },
//             ],
//         },
//         { name: "Serveur", color: "#f39c12", published: true, employees: [] },
//     ],
// };
