import * as React from "react";
import TextData from "../../components/atoms/TextData";
import CommingSoon from "../Settings/Elements/CommingSoon";
import SelectInput from "../../components/atoms/SelectInput";
import Button from "../../components/atoms/Button";
import PlanningTableHeader from "../../components/molecules/PlanningTableHeader";
import PlanningTableConfiguration from "../../components/molecules/PlanningTableConfiguration";
import PlanningTableBody from "../../components/organism/PlanningTableBody";
import PlanningTableFooter from "../../components/molecules/PlanningTableFooter";
import Loading from "../../components/atoms/Loading";
import moment from "moment";
import "./style.scss";
import PlanningShifts from "../../components/molecules/PlanningShifts";
import AttendanceCard from "../../components/organism/AttendanceCard";
import CustomOrderCard from "../../components/organism/CustomOrderCard";
import DuplicatePlanningCard from "../../components/organism/DuplicatePlanningCard";
import NewTicketCard from "../../components/organism/NewTicketCard";
import Modal from "../../components/atoms/Modal";
import AddNoteCard from "../../components/organism/AddNoteCard";

import Calendar from "../../components/organism/Calendar";
import PlanningSchedulerCard from "../../components/organism/PlanningSchedulerCard";
import ScheduleConfirmationCard from "../../components/organism/ScheduleConfirmationCard";
import PlanningDeletionCard from "../../components/organism/PlanningDeletionCard";
import PlanningEditionCard from "../../components/organism/PlanningEditionCard";
import PlanningPublishCard from "../../components/organism/PlanningPublishCard";
// import PlanningPublishCard from "../../components/organism/PlanningPublishCard";
import AddFixedPlanCard from "../../components/organism/AddFixedPlanCard";
import { ShiftOperator } from "typescript";
import { usePlanning } from "../../hooks/usePlanning";
import { useLanguage } from "../../hooks/useLanguage";
import {
    managePlans,
    formatTime,
    ReversefomatStartEndTime,
} from "../../_helpers/Functions";
import { useEmployees } from "../../hooks/useEmployees";
import { useBranch } from "../../hooks/useBranch";
import { truncateSync } from "fs";
import { ReactComponent as TickSVG } from "./tick.svg";
import { ReactComponent as PrintSVG } from "./print.svg";
import { ReactComponent as DownloadSVG } from "./download.svg";
import { ReactComponent as FullScreenSVG } from "./fullscreen.svg";
import { ReactComponent as ExitFullScreenSVG } from "./exitfullscreen.svg";

export default function Planning() {
    const {
        planningOptions,
        planningLoading,
        planningStartOfWeek: startOfWeek,
        planningEndOfWeek: endOfWeek,
        week,
        year,
        departments,
        loading,
        planningSelectedDepartment,
        planningInfoDepartments,
        planningInfo,
        employeesPlanningData,
        services,
        fixedPlans,
        setDraggedShiftType,
        steakers,
        absenceColors,
        loadingVerification: loadingVerification,
        toSearchPlanningEmployees,
        employeesData,
        planningCustomSort,
        planningDisponibility,
        sortedEmployeesWithOrder,
        getPlanningInfo,
        getPlanningInfoDepartments,
        getWeeklyPlanning,
        getUsersPlanning,
        getPlanningServices,
        getFixedPlans,
        printPlanning,
        verifyConvention,
        getEmployeesCustomSort,
        getDisponibility,
        getSteakers,
        getAbsenceColors,
        getSiteSettingsEtab,
        setPlanningEmployees,
        settoSearchPlanningEmployees,
        changeDaysServices,
        changeDepartmentsServices,
        getDepartments,
        selectDayPlanning,
        setHoursServices,
        handleOrderEmployees,
        handleUpdateEmployeesWithoutDepartment,
        changeTotalTime,
        calculateTime,
        getValidatedHolidays,
    } = usePlanning();
    const {
        getEmployees,
        employees,
        onUpdateFilterBy,
        filterBy,
        getPrimesTypes,
    } = useEmployees();
    const { selectedBranchSettings, getSiteSettings } = useBranch();

    const { switchFullScreen, isFullScreen } = useLanguage();
    const totalTime = React.useMemo<any>(() => {
        let emps: any = [...Object.values(employeesData || {})];
        return calculateTime(emps, employeesPlanningData);
    }, [employeesData, employeesPlanningData]);
    const totalTimeDepartment = React.useMemo<any>(() => {
        return changeTotalTime(
            employeesData,
            employeesPlanningData,
            departments,
        );
    }, [employeesData, employeesPlanningData, departments]);
    const [period, setPeriod] = React.useState("week");
    const [selectedDay, setSelectedDay] = React.useState<string>(
        moment().format("YYYY-MM-DD"),
    );

    React.useEffect(() => {
        if (employees) {
            let map: any = {};
            employees?.map((emp: any) => {
                // if (
                //     planning.employeesData &&
                //     planning.employeesData[emp?._id]
                // ) {
                const { _id } = emp;
                map[_id] = emp;
                // }
            });
            settoSearchPlanningEmployees(map);
        }
    }, [employees]);

    React.useEffect(() => {
        if (selectedDay) {
            selectDayPlanning(selectedDay);
        }
    }, [selectedDay]);
    const [openingTime, closingTime] = React.useMemo(() => {
        let opTime = 420;
        let clTime = 1440;
        if (employeesPlanningData !== null && employeesData !== null) {
            Object.keys(employeesData).forEach((empId) => {
                employeesPlanningData?.employees?.[empId]?.forEach(
                    (plan: any) => {
                        if (plan.day === selectedDay) {
                            plan.workShares?.forEach((shift: any) => {
                                const { from, to, type } = shift;
                                if (type === 1) {
                                    let from1 = from;
                                    let to1 = to;
                                    if (to1 <= from1) {
                                        to1 += 1440;
                                    }
                                    if (from1 <= opTime) opTime = from1;
                                    if (to1 >= clTime) clTime = to1;
                                }
                            });
                        }
                    },
                );
            });
            if (opTime > clTime) {
                opTime = 420;
                clTime = 0;
            }
        }
        return [opTime, clTime];
    }, [employeesPlanningData, employeesData, selectedDay]);
    const startCellHour = React.useMemo(() => {
        return Math.trunc(openingTime / 60) + (openingTime % 60 >= 30 ? 1 : 0);
    }, [openingTime]);
    const numberOfHourCellsInDay = React.useMemo(() => {
        if (closingTime > openingTime && closingTime - openingTime >= 180) {
            return (
                Math.trunc(closingTime / 60) +
                (closingTime % 60 > 30 ? 1 : 0) -
                (Math.trunc(openingTime / 60) +
                    (openingTime % 60 > 30 ? 1 : 0)) +
                1
            );
        } else {
            return (
                Math.trunc(closingTime / 60) +
                (closingTime % 60 > 30 ? 1 : 0) +
                (Math.trunc((1440 - openingTime) / 60) +
                    (openingTime % 60 > 30 ? 1 : 0)) +
                1
            );
        }
    }, [openingTime, closingTime]);

    React.useEffect(() => {
        if (
            selectedDay &&
            employeesData !== null &&
            employeesPlanningData !== null
        ) {
            let services: any = {};
            Object.keys(employeesData)?.map((employeeId: any) => {
                employeesPlanningData.employees?.[employeeId]
                    ?.find((plan: any) => plan.day === selectedDay)
                    ?.workShares?.map((shift: any) => {
                        const { from, to, type, typeAbsence } = shift;
                        if (type === 1 && !typeAbsence) {
                            let start = Math.trunc(from / 60);
                            let end = Math.trunc(to / 60);
                            if (end <= start) end += 24;
                            for (let i = start; i <= end; i++) {
                                if (services[i])
                                    services[i] = [
                                        ...services[i],
                                        shift.steaker?.name || "",
                                    ];
                                else services[i] = [shift.steaker?.name || ""];
                            }
                        }
                    });
            });
            setHoursServices({ hoursServices: services });
        }
    }, [selectedDay, employeesData, employeesPlanningData]);
    React.useEffect(() => {
        if (
            selectedDay &&
            employeesData !== null &&
            employeesPlanningData !== null &&
            departments !== null
        ) {
            let services: any = {};
            departments?.map((department: any, index: number) => {
                services[department._id] = {};
                department?.employees?.map((employeeID: string) => {
                    // let employee = planning.employeesData[employeeID];
                    employeesPlanningData?.employees?.[employeeID]
                        ?.find((plan: any) => plan.day === selectedDay)
                        ?.workShares?.map((shift: any) => {
                            const { from, to, type, typeAbsence } = shift;
                            if (type === 1 && !typeAbsence) {
                                let start = Math.trunc(from / 60);
                                let end = Math.trunc(to / 60);
                                if (end <= start) end += 24;
                                for (let i = start; i <= end; i++) {
                                    if (services[department._id][i])
                                        services[department._id][i] = [
                                            ...services[department._id][i],
                                            shift.steaker?.name || "",
                                        ];
                                    else {
                                        services[department._id][i] = [
                                            shift.steaker?.name || "",
                                        ];
                                    }
                                }
                            }
                        });
                });
            });
            setHoursServices({ departmentsHoursServices: services });
        }
    }, [selectedDay, employeesData, employeesPlanningData, departments]);

    React.useEffect(() => {
        changeDepartmentsServices();
        changeDaysServices();
    }, [employeesData, employeesPlanningData, services]);
    React.useEffect(() => {
        if (selectedBranchSettings._id && week) {
            getPlanningInfo();
            getUsersPlanning();
            getSteakers();
        }
    }, [selectedBranchSettings, week]);
    React.useEffect(() => {
        if (selectedBranchSettings._id && startOfWeek && endOfWeek) {
            getValidatedHolidays({ from: startOfWeek, to: endOfWeek });
        }
    }, [selectedBranchSettings, startOfWeek, endOfWeek]);

    React.useEffect(() => {
        if (selectedBranchSettings._id) {
            getSiteSettings();
            getEmployees({ archived: false });
            if (selectedBranchSettings && selectedBranchSettings._id) {
                getPrimesTypes({ siteId: selectedBranchSettings._id });

                onUpdateFilterBy({
                    ...filterBy,
                    name: "",
                    niveau: [],
                    role: [],
                    site: selectedBranchSettings._id
                        ? selectedBranchSettings._id.toString()
                        : undefined,
                });
            }
            getDepartments();
            getPlanningServices();
            getFixedPlans();
            getAbsenceColors();
            getSiteSettingsEtab();
        }
    }, [selectedBranchSettings]);
    React.useEffect(() => {
        if (employeesData !== null) {
            // handleOrderEmployees();
            handleUpdateEmployeesWithoutDepartment();
        }
    }, [departments, employeesData /*planning.customSort*/]);
    React.useEffect(() => {
        if (selectedBranchSettings._id && departments?.length && week) {
            getPlanningInfoDepartments();
        }
    }, [selectedBranchSettings, departments, week]);
    React.useEffect(() => {
        if (selectedBranchSettings._id && departments !== null) {
            getEmployeesCustomSort();
        }
    }, [selectedBranchSettings, departments]);

    const [selectedDepartment, setSelectedDepartment] = React.useState({
        name: "",
        depId: "",
    });
    const [openPublish, setOpenPublish] = React.useState(false);
    const [orderClick, setOrderClick] = React.useState(false);
    const [openDuplicate, setOpenDuplicate] = React.useState(false);
    const [openEdit, setOpenEdit] = React.useState(false);
    const [openDelete, setOpenDelete] = React.useState(false);
    const [openNote, setOpenNote] = React.useState(false);
    const [openNewFixedPlan, setOpenNewFixedPlan] = React.useState(false);
    const [isFixedEditing, setIsFixedEditing] = React.useState<boolean>(false);
    const [editThisShift, setEditThisShift] = React.useState(null);
    const toggleOpenNote = (value: any) => {
        setOpenNote(value);
    };
    const [dayNumber, setDayNumber] = React.useState(0);
    const [department, setDepartment] = React.useState(null);
    const [isEdit, setIsEdit] = React.useState(false);
    const handleOpenDuplicate = React.useCallback(
        (depId: string, name: string) => {
            setSelectedDepartment({ depId, name });
            setOpenDuplicate(true);
        },
        [],
    );
    const handleOpenEdit = (depId: any, name: string) => {
        setSelectedDepartment({ depId, name });
        setOpenEdit(true);
    };
    const handleOpenDelete = (depId: any, name: string) => {
        setSelectedDepartment({ depId, name });
        setOpenDelete(true);
    };
    const handleOpenDownload = (depId: any, name: string) => {
        // setOpenDuplicate(true);
    };
    const handleOpenPublish = (depId: any, name: string) => {
        setSelectedDepartment({ depId, name });
        setOpenPublish(true);
    };
    const nonPublishedDep = React.useMemo(() => {
        if (
            Object.values(planningInfoDepartments || {})?.length > 0 &&
            departments?.length > 0
        ) {
            let count = 0;
            Object.values(planningInfoDepartments)?.map((planInfo: any) => {
                if (!planInfo?.published) count++;
            });
            return count;
        }
        return 0;
    }, [planningInfoDepartments, departments]);
    const nonPublished = React.useMemo(() => {
        if (
            planningInfo &&
            (departments === null || departments?.length === 0)
        ) {
            if (planningInfo.published === false) {
                return 1;
            }
        }
        return 0;
    }, [planningInfo, departments]);

    React.useEffect(() => {
        if (
            employeesData !== null &&
            departments &&
            toSearchPlanningEmployees
        ) {
            getWeeklyPlanning();
            getDisponibility();
        }
    }, [
        employeesData,
        // employees,
        departments,
        toSearchPlanningEmployees,
    ]);

    // const targetRef = React.useRef<HTMLDivElement>(null);
    const toggleFullScreen = () => {
        switchFullScreen();
        // const target = targetRef.current;
        // if (document.fullscreenElement) {
        //     document.exitFullscreen();
        //     setIsFullScreen(false);
        //     // } else if (document.fullscreenElement) {
        //     //     document.exitFullscreen();
        //     // } else if (target?.requestFullscreen) {
        //     //     target.requestFullscreen();
        // } else if (target?.requestFullscreen) {
        //     target.requestFullscreen();
        //     setIsFullScreen(true);
        // }
    };
    //end try requests
    if (!selectedBranchSettings._id) {
        return (
            <div
                style={{
                    marginTop: "20px",
                    padding: "20px",
                    backgroundColor: "rgb(173 235 255)",
                    borderRadius: "8px",
                    color: "#4c5690",
                }}
            >
                Veuillez sélectionner un établissement
            </div>
        );
    }
    return (
        <div
            // ref={targetRef}
            style={{
                backgroundColor: "#f7f8fa",
                padding: isFullScreen ? "20px" : "",
                transition: "0.3s ease-in-out",
            }}
        >
            <div
                style={{
                    display: "flex",
                    width: "auto",
                    justifyContent: "space-between",
                    height: "80px",
                    paddingLeft: "20px",
                }}
            >
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        gap: "20px",
                    }}
                >
                    <TextData
                        color="#11106E"
                        size="34px"
                        fontWeight="bold"
                        style={{
                            padding: "0",
                        }}
                    >
                        Planning
                    </TextData>
                    <button
                        className="fullscreen-btn"
                        onClick={toggleFullScreen}
                    >
                        <div className="fullscreen-icon">
                            {isFullScreen ? (
                                <ExitFullScreenSVG
                                    height={18}
                                    width={18}
                                    color="white"
                                    style={{
                                        cursor: "pointer",
                                    }}
                                />
                            ) : (
                                <FullScreenSVG
                                    height={18}
                                    width={18}
                                    color="white"
                                    style={{
                                        cursor: "pointer",
                                    }}
                                />
                            )}
                        </div>
                        <div className="fullscreen-title">
                            <div>
                                {!isFullScreen
                                    ? "Mode plein écran"
                                    : "Quitter le mode plein écran"}
                            </div>
                        </div>
                    </button>
                </div>

                {period !== "month" &&
                    employeesData !== null &&
                    employeesPlanningData !== null &&
                    planningDisponibility !== null &&
                    departments !== null &&
                    planningCustomSort !== null &&
                    absenceColors !== null &&
                    planningInfo && (
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                alignSelf: "center",
                                gap: "10px",
                                height: "min-content",
                                padding: "0",
                            }}
                        >
                            {((departments?.length > 0 &&
                                nonPublishedDep > 0) ||
                                (departments?.length === 0 &&
                                    nonPublished > 0)) && (
                                <div
                                    style={{
                                        backgroundColor: "#fff1d4",
                                        color: "#d87e04",
                                        border: "2px solid #d87e04",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        gap: "10px",
                                        height: "50px",
                                        padding: "0.7rem 20px",
                                        borderRadius: "5px",
                                    }}
                                >
                                    {departments?.length > 0 &&
                                    nonPublishedDep > 0 ? (
                                        <>
                                            <div
                                                style={{
                                                    fontSize: "16px",
                                                    color: "white",
                                                    backgroundColor: "#d87e04",
                                                    height: "25px",
                                                    width: "25px",
                                                    borderRadius: "50%",
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                }}
                                            >
                                                {nonPublishedDep}
                                            </div>
                                            <div>
                                                planning
                                                {nonPublishedDep > 1 && "s"} non
                                                publié
                                                {nonPublishedDep > 1 && "s"} !
                                            </div>
                                        </>
                                    ) : (
                                        departments?.length === 0 &&
                                        nonPublished > 0 && (
                                            <div>
                                                Le planning n'est pas publié{" "}
                                            </div>
                                        )
                                    )}
                                </div>
                            )}
                            <Button
                                textColor="white"
                                backgroundColor="#2A8BAB"
                                style={{
                                    fontSize: "16px",
                                    gap: "0.25rem",
                                    // alignSelf: "flex-end",
                                    // marginTop: "1rem",
                                    // marginBottom: "0.5rem",
                                    border: "1px solid #2A8BAB",
                                }}
                                width="auto"
                                padding="0.75rem 20px"
                                height="50px"
                                onClick={() => printPlanning()}
                            >
                                <DownloadSVG />
                                Tous les plannings
                            </Button>
                        </div>
                    )}
            </div>
            <div
                className="planning-container"
                style={{
                    borderRadius: "12px",
                    maxHeight: `calc(100vh - ${isFullScreen ? 110 : 180}px)`,
                    height: `calc(100vh - ${isFullScreen ? 110 : 180}px)`,
                    transition: "0.3s ease-in-out",
                }}
            >
                <div className="table-popups">
                    <div onClick={(e) => e.stopPropagation()}>
                        <PlanningPublishCard
                            display={openPublish}
                            department={selectedDepartment}
                            onClose={() => setOpenPublish(false)}
                        />
                    </div>
                    <div onClick={(e) => e.stopPropagation()}>
                        <CustomOrderCard
                            display={orderClick}
                            onClose={() => setOrderClick(false)}
                            employees={sortedEmployeesWithOrder}
                        />
                    </div>
                    <div onClick={(e) => e.stopPropagation()}>
                        <DuplicatePlanningCard
                            display={openDuplicate}
                            department={selectedDepartment}
                            onClose={() => setOpenDuplicate(false)}
                        />
                    </div>
                    <div onClick={(e) => e.stopPropagation()}>
                        <PlanningEditionCard
                            display={openEdit}
                            department={selectedDepartment}
                            onClose={() => setOpenEdit(false)}
                        />
                    </div>
                    <div onClick={(e) => e.stopPropagation()}>
                        <PlanningDeletionCard
                            display={openDelete}
                            department={selectedDepartment}
                            onClose={() => setOpenDelete(false)}
                        />
                    </div>
                    <div onClick={(e) => e.stopPropagation()}>
                        <AddNoteCard
                            display={openNote}
                            onClose={() => {
                                setIsEdit(false);
                                toggleOpenNote(false);
                                setDepartment(null);
                            }}
                            isEdit={isEdit}
                            dayNumber={dayNumber}
                            startOfWeek={startOfWeek}
                            department={department}
                        />
                    </div>
                    <div onClick={(e) => e.stopPropagation()}>
                        <AddFixedPlanCard
                            display={openNewFixedPlan}
                            onClose={() => setOpenNewFixedPlan(false)}
                            isFixedEditing={isFixedEditing}
                            setIsFixedEditing={setIsFixedEditing}
                            editThisShift={editThisShift}
                            setEditThisShift={setIsFixedEditing}
                        />
                    </div>
                </div>
                <div
                    style={{
                        // maxHeight: "74vh",
                        // height: "fit-content",
                        height: "100%",
                        overflow: "auto",
                        width: "100%",
                        // backgroundColor: "white",

                        borderTopLeftRadius: "12px",
                        borderBottomLeftRadius: "12px",
                    }}
                >
                    <table className="planning-table">
                        <thead
                            style={{
                                position: "sticky",
                                top: "0",
                                // minHeight: "103px",
                                zIndex: "20",
                                boxShadow: "0px 3px 5px rgba(0, 0, 0, 0.1)",
                            }}
                        >
                            <PlanningTableHeader
                                orderClick={orderClick}
                                setOrderClick={setOrderClick}
                                setOpenDuplicate={setOpenDuplicate}
                                setOpenEdit={setOpenEdit}
                                setOpenDelete={setOpenDelete}
                                period={period}
                                setPeriod={setPeriod}
                                openingTime={openingTime}
                                closingTime={closingTime}
                                startCellHour={startCellHour}
                                numberOfHourCellsInDay={numberOfHourCellsInDay}
                                selectedDay={selectedDay}
                                setSelectedDay={setSelectedDay}
                                isFullScreen={isFullScreen}
                            />
                        </thead>
                        {
                            /*planning.loading*/ false ? (
                                <tr
                                    style={{
                                        position: "relative",
                                        width: "100%",
                                        height: "80vh",
                                        backgroundColor: "#FFF",
                                    }}
                                >
                                    <th colSpan={8}>
                                        <Loading width="400px" height="200px" />
                                    </th>
                                </tr>
                            ) : (
                                <>
                                    {/* {!planning.loading &&
                                    planning.services &&
                                    period !== "month" && (
                                        <PlanningTableConfiguration
                                            isServices={
                                                planning.services?.length > 0
                                            }
                                            startOfWeek={planning.startOfWeek}
                                            notes={
                                                planning?.planningInfo?.note ||
                                                []
                                            }
                                            serviceValues={
                                                planning.daysServices || []
                                            }
                                            toggleOpenNote={toggleOpenNote}
                                            setDayNumber={setDayNumber}
                                            setIsEdit={setIsEdit}
                                            period={period}
                                            openingTime={openingTime}
                                            closingTime={closingTime}
                                            numberOfHourCellsInDay={
                                                numberOfHourCellsInDay
                                            }
                                            selectedDay={selectedDay}
                                            isNotes={true}
                                            isMeteo={false}
                                            startCellHour={startCellHour}
                                        />
                                    )} */}
                                    {period === "month" && (
                                        <tr
                                            style={{
                                                maxHeight: `calc(100vh - ${
                                                    isFullScreen ? 165 : 230
                                                }px)`,
                                                height: `calc(100vh - ${
                                                    isFullScreen ? 165 : 230
                                                }px)`,
                                                position: "relative",
                                                backgroundColor: "white",
                                            }}
                                        >
                                            <th colSpan={9}>
                                                <CommingSoon page={"month"} />
                                            </th>
                                        </tr>
                                    )}
                                    {period !== "month" &&
                                        employeesData !== null &&
                                        employeesPlanningData !== null &&
                                        planningDisponibility !== null &&
                                        departments !== null &&
                                        planningCustomSort !== null &&
                                        absenceColors !== null && (
                                            <PlanningTableBody
                                                period={period}
                                                openingTime={openingTime}
                                                closingTime={closingTime}
                                                numberOfHourCellsInDay={
                                                    numberOfHourCellsInDay
                                                }
                                                totalTime={totalTimeDepartment}
                                                selectedDay={selectedDay}
                                                startCellHour={startCellHour}
                                                handleOpenDuplicate={
                                                    handleOpenDuplicate
                                                }
                                                handleOpenEdit={handleOpenEdit}
                                                handleOpenDelete={
                                                    handleOpenDelete
                                                }
                                                handleOpenDownload={
                                                    handleOpenDownload
                                                }
                                                handleOpenPublish={
                                                    handleOpenPublish
                                                }
                                                toggleOpenNote={toggleOpenNote}
                                                setDayNumber={setDayNumber}
                                                setIsEdit={setIsEdit}
                                                setDepartment={setDepartment}
                                            />
                                        )}
                                    {period !== "month" &&
                                        employeesData !== null &&
                                        employeesPlanningData !== null &&
                                        planningDisponibility !== null &&
                                        departments !== null &&
                                        planningCustomSort !== null &&
                                        absenceColors !== null && (
                                            <PlanningTableFooter
                                                totalTime={totalTime}
                                                selectedDay={selectedDay}
                                                week={week}
                                                period={period}
                                                isTotal={true}
                                            />
                                        )}
                                </>
                            )
                        }
                    </table>
                </div>
                {!planningOptions.hideFixedShifts && (
                    <PlanningShifts
                        setOpenNewFixedPlan={setOpenNewFixedPlan}
                        setEditThisShift={setEditThisShift}
                        setIsFixedEditing={setIsFixedEditing}
                        fixedPlans={fixedPlans}
                        setDraggedShiftType={setDraggedShiftType}
                    />
                )}
            </div>
        </div>
    );
}
