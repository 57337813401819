import moment from "moment";
import { absencePayload } from "../redux/features/settings/types";
import { AnyNode } from "postcss";
const { DateTime } = require("luxon");

const hourRegexPatter = /^\d{2}:\d{2}$/; // regex pattern to match "HH:MM" format
export const validSalaryTypes = ["daily", "hourly"];
export const absences: absencePayload[] = [
    { _id: null, absence: "Repos hebdomadaire", color: null, site: null },
    { _id: null, absence: "Absence injustifiée", color: null, site: null },
    { _id: null, absence: "Absence justifiée", color: null, site: null },
    { _id: null, absence: "Accident du travail", color: null, site: null },
    // { _id: null, absence: "Arrêt maladie", color: null, site: null },
    { _id: null, absence: "Chômage technique", color: null, site: null },
    // { _id: null, absence: "Congé maternité", color: null, site: null },
    // { _id: null, absence: "Congé parental", color: null, site: null },
    // { _id: null, absence: "Congé paternité", color: null, site: null },
    // { _id: null, absence: "Congé payé", color: null, site: null },
    // { _id: null, absence: "Congé sans solde", color: null, site: null },
    { _id: null, absence: "Évènement familial", color: null, site: null },
    { _id: null, absence: "Formation", color: null, site: null },
    {
        _id: null,
        absence: "Indisponibilité ponctuelle",
        color: null,
        site: null,
    },
    // { _id: null, absence: "Jour férié", color: null, site: null },
    {
        _id: null,
        absence: "Maladie professionnelle",
        color: null,
        site: null,
    },
    {
        _id: null,
        absence: "Mise à pied conservatoire",
        color: null,
        site: null,
    },
    {
        _id: null,
        absence: "Mise à pied disciplinaire",
        color: null,
        site: null,
    },
    {
        _id: null,
        absence: "Repos compensateur d'habillement",
        color: null,
        site: null,
    },
    {
        _id: null,
        absence: "Repos compensateur de nuit",
        color: null,
        site: null,
    },
    { _id: null, absence: "Visite médicale", color: null, site: null },
];
export function isHourRegex(value: string) {
    return hourRegexPatter.test(value);
}
export function isNumber(value: any) {
    return typeof value === "number" && !isNaN(value);
}
export function isValidSalaryFrequencyType(salaryType: string) {
    return validSalaryTypes.includes(salaryType);
}
export function extractNumber(inputStr: string | number) {
    if (!inputStr || inputStr === "") return 0;
    if (typeof inputStr === "number") return inputStr;
    const cleanedStr = inputStr.replace(/[^\d.-]/g, "");

    const result = parseFloat(cleanedStr);

    return isNaN(result) ? 0 : result;
}
export function sortByFrom(shifts: any[]) {
    return shifts.sort((a, b) => a.from - b.from);
}
export function capitalize(str: string) {
    return str?.charAt(0).toUpperCase() + str?.slice(1);
}
export function getWeeksInMonth(year: any, month: any) {
    if (year == null || month == null) {
        return [];
    }
    const weeks = [];
    const firstDayOfMonth = moment({
        year,
        month: extractNumber(month) - 1,
        day: 1,
    });
    const lastDayOfMonth = moment({
        year,
        month: extractNumber(month) - 1,
        day: 1,
    }).endOf("month");
    let currentWeek = firstDayOfMonth.clone().startOf("week");

    while (currentWeek.isBefore(lastDayOfMonth)) {
        weeks.push(currentWeek.week());
        currentWeek.add(1, "week");
    }

    return weeks;
}
export function getWeeksBetweenDates(startDate: any, endDate: any) {
    if (!startDate || !endDate) {
        return [];
    }

    const start = moment(startDate).startOf("week");
    const end = moment(endDate).endOf("week");

    const weeks = [];
    let currentWeek = start.clone();

    while (currentWeek.isSameOrBefore(end)) {
        weeks.push(currentWeek.week());
        currentWeek.add(1, "week");
    }

    return weeks;
}
export function getCurrentWeekNumberAndDays(date: any, exlcude: Number[] = []) {
    const currentMoment = moment(date);
    const weekNumber = currentMoment.week();
    const year = String(currentMoment.year());
    // Start of the week (Monday) for the given date
    const startOfWeek = currentMoment.clone().startOf("isoWeek");

    // An array to hold the days of the week
    const weekDays = [];

    // Loop to get the dates of the week (from Monday to Sunday)
    for (let i = 0; i < 7; i++) {
        if (exlcude.includes(i)) continue;
        weekDays.push(startOfWeek.clone().add(i, "days").format("YYYY-MM-DD"));
    }

    return { year, weekNumber, weekDays };
}
export function getPlanningCustomFormattedDate(date: any) {
    const formattedDate = moment(date).format("dddd D");
    return formattedDate?.charAt(0)?.toUpperCase() + formattedDate?.slice(1);
}
export function getPlanningCustomFormattedWeekNumberAndYear(
    date: string,
    format: string,
) {
    // const startDate = moment(date, format)
    //     .isoWeek(moment(date, format).week())
    //     .startOf("isoWeek")
    //     .locale("fr");
    // const endDate = moment(date, format)
    //     .isoWeek(moment(date, format).week())
    //     .endOf("isoWeek")
    //     .locale("fr");
    const dateWithinWeek = moment(date, format);
    const startDate = dateWithinWeek.clone().startOf("week");
    const endDate = dateWithinWeek.clone().endOf("week");
    const formattedStartDate = startDate.format("dddd D MMMM");
    const formattedEndDate = endDate.format("dddd D MMMM");

    const weekRange = `${formattedStartDate} au ${formattedEndDate}`;
    return weekRange.slice(0, 1).toUpperCase() + weekRange.slice(1);
}
export function getDatesInRange(
    startDate: any,
    endDate: any,
    dateFormat: string,
): string[] {
    let dates = [];

    if (startDate && endDate) {
        let start = moment(startDate).startOf("day");
        let end = moment(endDate).startOf("day");

        while (start.isSameOrBefore(end, "day")) {
            dates.push(start.format(dateFormat));
            start.add(1, "day");
        }
    } else if (startDate) {
        dates.push(moment(startDate).format(dateFormat));
    } else if (endDate) {
        dates.push(moment(endDate).format(dateFormat));
    }

    return dates;
}
export function getDatesIndexes(startDate: any, endDate: any): number[] {
    let datesIndexes = [];
    if (startDate && endDate) {
        let start = moment(startDate).startOf("day");
        let end = moment(endDate).startOf("day");
        while (start.isSameOrBefore(end, "day")) {
            datesIndexes.push(start.isoWeekday());
            start.add(1, "day");
        }
    } else if (startDate) {
        datesIndexes.push(moment(startDate).isoWeekday());
    } else if (endDate) {
        datesIndexes.push(moment(endDate).isoWeekday());
    }
    return datesIndexes;
}
export function fomatStartEndTime(minutes: string | number) {
    if (typeof minutes === "string") {
        minutes = extractNumber(minutes);
    }
    const hours = Math.floor(minutes / 60);
    const mins = minutes % 60;
    const formattedHours = String(hours).padStart(2, "0");
    const formattedMins = String(mins).padStart(2, "0");
    return `${formattedHours}:${formattedMins}`;
}
export function ReversefomatStartEndTime(time: string) {
    const hours = parseInt(time.slice(0, 2), 10);
    const mins = parseInt(time.slice(3), 10);
    return hours * 60 + mins;
}

export function formatTime(
    minutes: string | number,
    withoutMinutes: boolean = false,
    onlyHours: boolean = false,
) {
    if (typeof minutes === "string") {
        minutes = extractNumber(minutes);
    }
    minutes = Math.abs(minutes);
    if (Math.abs(minutes) < 60 && !onlyHours)
        return `${minutes} minute${minutes > 1 ? "s" : ""}`;
    // Format : 15h30
    let hours = Math.floor(minutes / 60);
    if (hours === 24) hours = 0;
    const min = minutes % 60;
    return `${hours < 10 ? "0" : ""}${hours}h${
        !withoutMinutes && min < 10 ? "0" : ""
    }${!withoutMinutes ? Math.round(min) : ""}`;
}
export function convertMinutesToHours(
    minutes: string | number,
    withSign: boolean = true,
) {
    if (typeof minutes === "string") {
        minutes = extractNumber(minutes);
    }
    const sign = minutes < 0 ? "-" : "";
    minutes = Math.abs(minutes);
    let hours = minutes / 60;
    // const hours = Math.floor(minutes / 60);
    // const remainingMinutes = (minutes % 60) / 60;
    // const res = hours + remainingMinutes;
    const decimalPart = hours % 1 !== 0 ? hours.toFixed(2).split(".")[1] : "";
    const formattedHours = decimalPart
        ? hours.toFixed(2)
        : Math.floor(hours).toString();
    return `${withSign ? sign : ""}${formattedHours}h`;
    // return `${withSign ? sign : ""}${hours.toFixed(2)}h`;
}

export function employeeRoleColor(role: string) {
    if (!role) return "#00A42E";
    switch (role.toLowerCase().trim()) {
        case "admin":
        case "administrator":
            return "#FB3640";
        case "manager":
            return "#6628F1";
        case "employee":
            return "#00A42E";
        default:
            return "#00A42E";
    }
}
export function getColorRgba(color: string, outlineOpacity: string = "0.2") {
    const rgbaValue = `rgba(${parseInt(color.slice(-6, -4), 16)}, ${parseInt(
        color.slice(-4, -2),
        16,
    )}, ${parseInt(color.slice(-2), 16)}, ${outlineOpacity})`;
    return rgbaValue;
}

export function generateWeekList(startDate: any, endDate: any) {
    let currentWeek = moment(startDate).startOf("isoWeek");
    const endWeek = moment(endDate).startOf("isoWeek");
    const weekList = [];

    while (currentWeek.isSameOrBefore(endWeek)) {
        const weekNumber = currentWeek.isoWeek();
        const year = currentWeek.year();
        const weekStartDate = currentWeek.format("D");
        const weekEndDate = currentWeek
            .clone()
            .endOf("isoWeek")
            .format("D MMMM YYYY");
        const weekText = `${weekStartDate} - ${weekEndDate}`;
        weekList.push({ date: weekText, weekNumber, year });
        currentWeek.add(1, "week");
    }
    // return weekList.reverse();
    return weekList;
}
export function generateDayList(
    startDate: any,
    endDate: any,
    format: string = "dddd D MMMM YYYY",
) {
    const start = moment(startDate);
    const end = moment(endDate);
    const dayList = [];

    while (start.isSameOrBefore(end, "day")) {
        const dayFormatted = start.format(format);
        dayList.push(dayFormatted);

        start.add(1, "day");
    }

    return dayList;
}
export function isToday(date: Date) {
    const today = new Date();
    return (
        date.getDate() === today.getDate() &&
        date.getMonth() === today.getMonth() &&
        date.getFullYear() === today.getFullYear()
    );
}

export function formatDate(date: Date): string {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    return `${year}-${month}-${day}`;
}
//planning
export const managePlans = (
    plans: any,
    splitBySite = true,
    splitByEmployees = true,
    splitPlanByDay = true,
    splitPlanByStartTime = false,
    sortWorkShares = false,
    sortPlansByDay = false,
) => {
    if (!splitBySite) {
        return plans;
    }

    const plansBySite = groupPlansBy(plans, "site._id");

    if (!splitByEmployees) {
        return plansBySite.map((planBySite) => ({
            site: planBySite[0]?.site.name,
            plans: planBySite,
        }));
    }

    const plansByEmployees = plansBySite.map((siteGroup) =>
        groupPlansBy(siteGroup, "employee"),
    );

    if (!splitPlanByDay) {
        return plansByEmployees.map((planBySite) => ({
            site: planBySite[0][0]?.site.name,
            employees: planBySite.map((planByEmp) => ({
                employee: planByEmp[0]?.employee,
                plans: planByEmp,
            })),
        }));
    }

    const plansByDay = plansByEmployees.map((employeeGroup: any) =>
        groupPlansBy(employeeGroup, "day"),
    );

    const transformPlans = (plansByDay: any) => {
        let result = plansByDay.map((plansOfEachSite: any) => ({
            site: plansOfEachSite[0][0][0]?.site.name,
            employees: transformEmployees(plansOfEachSite),
        }));
        // console.log("result: ", result);
        while (result?.length === 1) {
            result = result[0];
        }
        if (result?.employees?.length === 1) {
            result.employees = result.employees[0];
        }
        return result;
    };

    const transformEmployees = (plansOfEachSite: any) => {
        return plansOfEachSite.map((plansOfEachEmployee: any) =>
            plansOfEachEmployee.map((employeeWorkShare: any) => ({
                employeeId: employeeWorkShare[0].employee,
                employeePlansPerDay: groupEmployeePlans(employeeWorkShare),
            })),
        );
    };

    const groupEmployeePlans = (employeeWorkShare: any) => {
        const plans: any = [];

        for (const employeeData of employeeWorkShare) {
            employeeData.day = moment(employeeData.day).format("YYYY-MM-DD");
            const day = employeeData.day;
            const existingDay = plans.find(
                (elem: any) =>
                    moment(elem.day).format("YYYY-MM-DD") ===
                    moment(day).format("YYYY-MM-DD"),
            );

            if (existingDay) {
                existingDay.workShares.push(employeeData);
            } else {
                plans.push({
                    day: moment(day).format("YYYY-MM-DD"),
                    workShares: [employeeData],
                });
            }
        }
        if (sortWorkShares) {
            plans.forEach((plan: any) => {
                plan.workShares.sort(
                    (a: any, b: any) => a.from - b.from || a.to - b.to,
                );
            });
        }

        if (sortPlansByDay) {
            plans.sort(
                (a: any, b: any) =>
                    new Date(a.day).getTime() - new Date(b.day).getTime(),
            );
        }
        return plans;
    };

    return transformPlans(plansByDay);
};
//function to clean the planning data:
function groupPlansBy(plans: any, key: any) {
    const groupedPlans: { [key: string]: any[] } = {};

    plans.forEach((plan: any) => {
        let groupKey = "default";
        if (key) {
            const keys = key.split(".");
            let currentObj: any = plan;
            for (const k of keys) {
                currentObj = currentObj[k];
                if (currentObj === undefined) {
                    break;
                }
            }
            if (currentObj !== undefined) {
                groupKey = currentObj;
            }
        }

        if (!groupedPlans[groupKey]) {
            groupedPlans[groupKey] = [];
        }
        groupedPlans[groupKey].push(plan);
    });

    return Object.values(groupedPlans);
}

// Function to calculate Holiday periode
export const calculateDays = (
    fromDay: string,
    fromHour: string,
    toDay: string | null,
    toHour: string,
) => {
    const fromDayLuxon = moment(
        `${moment.parseZone(fromDay).format("DD/MM/YYYY")} ${fromHour}`,
        "DD/MM/YYYY HH:mm",
    );
    const toDayLuxon = moment(
        `${moment.parseZone(toDay).format("DD/MM/YYYY")} ${toHour}`,
        "DD/MM/YYYY HH:mm",
    );

    const diffInDays = Math.ceil(toDayLuxon.diff(fromDayLuxon, "days", true)); // Get difference in days

    return parseFloat(diffInDays.toFixed(1));
};

// Type of conges
export const congeTypes = [
    { name: "Congé payé", value: "paid_leave" },
    { name: "Congé payé", value: "congé payé" },
    { name: "Congé sans solde", value: "unpaid_leave" },
    { name: "Évènement familial", value: "family_event" },
    { name: "Indisponibilité ponctuelle", value: "temporary_unavailability" },
    { name: "Repos hebdomadaire", value: "repos_hebdomadaire" },
    { name: "Absence injustifiée", value: "absence_injustifiee" },
    { name: "Absence injustifiée", value: "absence injustifiée" },
    { name: "Absence justifiée", value: "absence_justifiee" },
    { name: "Absence justifiée", value: "absence justifiée" },
    { name: "Accident du travail", value: "accident_du_travail" },
    { name: "Arrêt maladie", value: "arret_maladie" },
    { name: "Arrêt maladie", value: "arrêt maladie" },
    { name: "Chômage technique", value: "chomage_technique" },
    { name: "Congé maternité", value: "conge_maternite" },
    { name: "Congé parental", value: "conge_parental" },
    { name: "Congé parental", value: "congé parenta" },
    { name: "Congé paternité", value: "conge_paternite" },
    { name: "Congé paternité", value: "congé paternité" },
    { name: "Formation", value: "formation" },
    { name: "Jour férié", value: "jour_ferie" },
    { name: "Maladie professionnelle", value: "maladie_professionnelle" },
    { name: "Mise à pied conservatoire", value: "mise_a_pied_conservatoire" },
    { name: "Mise à pied disciplinaire", value: "mise_a_pied_disciplinaire" },
    {
        name: "Repos compensateur d'habillement",
        value: "repos_compensateur_de_habillement",
    },
    { name: "Repos compensateur de nuit", value: "repos_compensateur_de_nuit" },
    { name: "Visite médicale", value: "visite_medicale" },
    {
        name: "Réduction du temps de travail",
        value: "réduction du temps de travail (rtt)",
    },
];
