import * as React from "react";
import Modal from "../../atoms/Modal";
import PopUpCard from "../../molecules/PopUpCard";
import TextData from "../../atoms/TextData";
import Button from "../../atoms/Button";
import "./style.css";
import DotTicket from "../../atoms/DotTicket";
import SelectInput from "../../atoms/SelectInput";
import Input from "../../atoms/Input";

import NewTicket from "../../atoms/NewTicket";
import ToggleButton from "../../atoms/Toggle";
import NewShiftTicketCard from "../NewShiftTicketCard";
import { fomatStartEndTime } from "../../../_helpers/Functions";
import { usePlanning } from "../../../hooks/usePlanning";
import ShiftTimeInputs from "./ShiftTimeInputs";

function AddShift(props: any) {
    const { shiftFixedData, setShiftFixedData, editedShift } = props;

    const { steakers, siteSettingsEtab, addNewSteakers } = usePlanning();

    const [addNewTicket, setAddNewTicket] = React.useState(false);
    const [pauseDisabled, setPauseDisabled] = React.useState(false);

    React.useEffect(() => {
        setPauseDisabled(
            // editedShift &&
            Number(siteSettingsEtab.pause) === Number(shiftFixedData.pause),
        );
    }, [/*editedShift,*/ siteSettingsEtab.pause, shiftFixedData.pause]);
    // React.useEffect(() => {
    //     setPauseDisabled(
    //         //editedShift &&
    //         Number(planning.siteSettingsEtab.pause) === Number(shiftData.pause)
    //     );
    // }, [/*editedShift, */ planning.siteSettingsEtab.pause, shiftData.pause]);

    const [simpleSteaker, setSimpleSteakers] = React.useState<any>([]);
    const [withTimeSteaker, setWithTimeSteakers] = React.useState<any>([]);
    React.useEffect(() => {
        if (steakers?.length > 0) {
            setSimpleSteakers([
                ...steakers?.filter(
                    (steaker: any) => !steaker.fromHour && !steaker.toHour,
                ),
            ]);
            setWithTimeSteakers([
                ...steakers?.filter(
                    (steaker: any) => steaker.fromHour || steaker.toHour,
                ),
            ]);
        }
    }, [steakers]);
    const handleAddNewSteaker = async (data: any) => {
        let ok = await addNewSteakers(data);
        if (ok) {
            setAddNewTicket(false);
        }
    };
    return (
        <div
            className="add-fixed-shift-container"
            style={{
                padding: "30px",
            }}
        >
            <ShiftTimeInputs
                shiftFixedData={shiftFixedData}
                setShiftFixedData={setShiftFixedData}
                pauseDisabled={pauseDisabled}
                setPauseDisabled={setPauseDisabled}
                planningDefaultPause={siteSettingsEtab?.pause}
            />

            <div className="add-tickets-container">
                <TextData
                    variant="label"
                    size="12px"
                    fontWeight="500"
                    color="#2a346d"
                >
                    Choisir une étiquette
                </TextData>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        marginTop: 5,
                        width: "100%",
                    }}
                >
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            flexWrap: "wrap",
                            gap: "20px",
                            width: "100%",
                            margin: "3px 0",
                        }}
                    >
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                flexWrap: "wrap",
                                gap: 8,
                                width: "100%",
                            }}
                        >
                            {simpleSteaker?.map((ticket: any, index: any) => {
                                return (
                                    <DotTicket
                                        key={index}
                                        color={ticket.color}
                                        selected={
                                            shiftFixedData.steaker ===
                                            ticket._id
                                        }
                                        onClick={() =>
                                            setShiftFixedData((prev: any) => ({
                                                ...prev,
                                                steaker:
                                                    prev.steaker === ticket._id
                                                        ? ""
                                                        : ticket._id,
                                            }))
                                        }
                                        style={{
                                            width: "calc(calc(100% - 16px) / 3)",
                                        }}
                                    >
                                        <div
                                            style={{
                                                width: "100%",
                                                display: "flex",
                                                flexDirection:
                                                    ticket.name?.length <= 15
                                                        ? "row"
                                                        : "column",
                                                justifyContent:
                                                    ticket.name?.length <= 15
                                                        ? "flex-start"
                                                        : "center",
                                                alignItems:
                                                    ticket.name?.length <= 15
                                                        ? "center"
                                                        : "flex-start",
                                                gap: "3px",
                                            }}
                                        >
                                            <div
                                                style={{
                                                    fontSize: "medium",
                                                }}
                                            >
                                                {ticket.name.slice(0, 15)}
                                                {ticket.name?.length > 15 &&
                                                    "..."}
                                            </div>
                                            {(ticket.fromHour ||
                                                ticket.toHour) && (
                                                <div
                                                    style={{
                                                        fontSize: "small",
                                                        fontWeight: "bold",
                                                    }}
                                                >
                                                    (
                                                    {ticket.fromHour !== ""
                                                        ? ticket.fromHour
                                                        : " ... "}
                                                    {" - "}
                                                    {ticket.toHour !== ""
                                                        ? ticket.toHour
                                                        : " ... "}
                                                    )
                                                </div>
                                            )}
                                        </div>
                                    </DotTicket>
                                );
                            })}
                        </div>
                        <hr />
                        <TextData
                            variant="label"
                            size="12px"
                            fontWeight="500"
                            color="#2a346d"
                        >
                            Étiquette avec heure prédéfinie
                        </TextData>
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                flexWrap: "wrap",
                                gap: 8,
                                width: "100%",
                            }}
                        >
                            {withTimeSteaker?.map((ticket: any, index: any) => {
                                return (
                                    <DotTicket
                                        key={index}
                                        color={ticket.color}
                                        selected={
                                            shiftFixedData.steaker ===
                                            ticket._id
                                        }
                                        onClick={() =>
                                            setShiftFixedData((prev: any) => ({
                                                ...prev,
                                                steaker:
                                                    prev.steaker === ticket._id
                                                        ? ""
                                                        : ticket._id,
                                                from: ticket.fromHour
                                                    ? ticket.fromHour
                                                    : prev.from,
                                                to: ticket.toHour
                                                    ? ticket.toHour
                                                    : prev.to,
                                            }))
                                        }
                                        style={{
                                            width: "calc(calc(100% - 8px) / 2)",
                                        }}
                                    >
                                        <div
                                            style={{
                                                width: "100%",
                                                display: "flex",
                                                flexDirection:
                                                    ticket.name?.length <= 15
                                                        ? "row"
                                                        : "column",
                                                justifyContent:
                                                    ticket.name?.length <= 15
                                                        ? "flex-start"
                                                        : "center",
                                                alignItems:
                                                    ticket.name?.length <= 15
                                                        ? "center"
                                                        : "flex-start",
                                                gap: "3px",
                                            }}
                                        >
                                            <div
                                                style={{
                                                    fontSize: "medium",
                                                }}
                                            >
                                                {ticket.name.slice(
                                                    0,
                                                    ticket.fromHour ||
                                                        ticket.toHour
                                                        ? 15
                                                        : 20,
                                                )}
                                                {ticket.name?.length > 15 &&
                                                    "..."}
                                            </div>
                                            {(ticket.fromHour ||
                                                ticket.toHour) && (
                                                <div
                                                    style={{
                                                        fontSize: "small",
                                                        fontWeight: "bold",
                                                    }}
                                                >
                                                    (
                                                    {ticket.fromHour !== ""
                                                        ? ticket.fromHour
                                                        : " ... "}
                                                    {" - "}
                                                    {ticket.toHour !== ""
                                                        ? ticket.toHour
                                                        : " ... "}
                                                    )
                                                </div>
                                            )}
                                        </div>
                                    </DotTicket>
                                );
                            })}
                        </div>
                    </div>
                    <NewTicket
                        width="154px"
                        text="Nouvelle étiquette"
                        textColor="#4C5690"
                        textSize="12px"
                        iconColor="#4C5690"
                        handleAddNewTicket={() => setAddNewTicket(true)}
                    />
                    <Modal
                        modalEnabled={addNewTicket}
                        onClose={() => setAddNewTicket(false)}
                    >
                        <NewShiftTicketCard
                            onClose={() => setAddNewTicket(false)}
                            displayColorsCount={999}
                            onSubmitButton={handleAddNewSteaker}
                        />
                    </Modal>
                </div>
            </div>
            <div className="text-area-shift-container">
                <Input
                    captionText="Notes"
                    captionColor="#2a346d"
                    placeholder="Ecrire ici..."
                    value={shiftFixedData.comment}
                    onChange={(value: string) =>
                        setShiftFixedData((prev: any) => ({
                            ...prev,
                            comment: value,
                        }))
                    }
                    inputWidth="100%"
                    captionSize="12px"
                    inputRadius="4px"
                    textColor="#4C5690"
                    textSize={14}
                    iconLocation="right"
                    borderColor="#C7CBE2"
                    borderWidth={1}
                    style={{
                        inputStyle: {
                            minHeight: "48px",
                            fontWeight: "400",
                            padding: "10px",
                        },
                        captionStyle: {
                            fontWeight: "500",
                            marginTop: 22,
                        },
                    }}
                />
            </div>
        </div>
    );
}
function AddAbsence(props: any) {
    const { absenceFixedData, setAbsenceFixedData } = props;

    const AbsenceTimeInputs = () => {
        return (
            <div
                className="add-shift-time-container"
                style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "start",
                    flexWrap: "wrap",
                    gap: 16,
                }}
            >
                <Input
                    type="time"
                    captionText={"Début"}
                    value={absenceFixedData.from}
                    onChange={(value: any) =>
                        setAbsenceFixedData((prev: any) => ({
                            ...prev,
                            from: value,
                        }))
                    }
                    inputWidth="110px"
                    borderColor="#C7CBE2"
                    captionColor="#2a346d"
                    borderWidth={1}
                    captionSize="12px"
                    // iconImg={Clock}
                    inputRadius="4px"
                    textColor="#4C5690"
                    textSize={14}
                    iconLocation="right"
                    style={{
                        inputStyle: {
                            minHeight: "48px",
                            fontWeight: "400",
                        },
                        captionStyle: {
                            fontWeight: "500",
                        },
                    }}
                />
                <Input
                    type="time"
                    captionText={"Fin"}
                    value={absenceFixedData.to}
                    onChange={(value: any) =>
                        setAbsenceFixedData((prev: any) => ({
                            ...prev,
                            to: value,
                        }))
                    }
                    inputWidth="110px"
                    borderColor="#C7CBE2"
                    captionColor="#2a346d"
                    borderWidth={1}
                    captionSize="12px"
                    inputRadius="4px"
                    textColor="#4C5690"
                    textSize={14}
                    iconLocation="right"
                    style={{
                        inputStyle: {
                            minHeight: "48px",
                            fontWeight: "400",
                        },
                        captionStyle: {
                            fontWeight: "500",
                        },
                    }}
                />
            </div>
        );
    };
    const absenceTypes = [
        { label: "Évènement familial", value: "Évènement familial" },
        {
            label: "Indisponibilité ponctuelle",
            value: "Indisponibilité ponctuelle",
        },
        { label: "Repos hebdomadaire", value: "Repos hebdomadaire" },
        { label: "Absence injustifiée", value: "Absence injustifiée" },
        { label: "Absence justifiée", value: "Absence justifiée" },
        { label: "Accident du travail", value: "Accident du travail" },

        { label: "Chômage technique", value: "Chômage technique" },
        { label: "Formation", value: "Formation" },
        { label: "Maladie professionnelle", value: "Maladie professionnelle" },
        {
            label: "Mise à pied conservatoire",
            value: "Mise à pied conservatoire",
        },
        {
            label: "Mise à pied disciplinaire",
            value: "Mise à pied disciplinaire",
        },
        {
            label: "Repos compensateur d'habillement",
            value: "Repos compensateur d'habillement",
        },
        {
            label: "Repos compensateur de nuit",
            value: "Repos compensateur de nuit",
        },
        { label: "Visite médicale", value: "Visite médicale" },
        {
            label: "Réduction du temps de travail (RTT)",
            value: "Réduction du temps de travail (RTT)",
        },
    ];
    const [allTheDay, setallTheDay] = React.useState(false);
    React.useEffect(() => {
        if (
            (absenceFixedData.from === 0 ||
                absenceFixedData.from === "00:00") &&
            (absenceFixedData.to === 1439 || absenceFixedData.to === "23:59")
        ) {
            setallTheDay(true);
        }
    }, [absenceFixedData]);
    return (
        <div className="add-absence-container">
            <div className="absence-type-container">
                <SelectInput
                    title="Type d'absence"
                    captionColor="#2a346d"
                    options={absenceTypes}
                    selectedValue={{
                        label: absenceFixedData.typeAbsence,
                        value: absenceFixedData.typeAbsence,
                    }}
                    onSelectOption={(option) =>
                        setAbsenceFixedData((prev: any) => ({
                            ...prev,
                            typeAbsence: option.value,
                        }))
                    }
                ></SelectInput>
            </div>
            <div
                className="toggle-allday-container"
                style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                }}
            >
                <TextData
                    variant="div"
                    width="auto"
                    size="12px"
                    fontWeight="500"
                    color="#2a346d"
                >
                    Toute la journée
                </TextData>
                <ToggleButton
                    isChecked={allTheDay}
                    onClick={() =>
                        setallTheDay((prev) => {
                            if (!prev) {
                                setAbsenceFixedData((data: any) => ({
                                    ...data,
                                    from: "00:00",
                                    to: "23:59",
                                }));
                            } else {
                                setAbsenceFixedData((data: any) => ({
                                    ...data,
                                    from: "08:00",
                                    to: "12:00",
                                }));
                            }
                            return !prev;
                        })
                    }
                />
            </div>
            {!allTheDay && <AbsenceTimeInputs />}
            <div
                className="absence-note-container"
                style={{
                    width: "100%",
                }}
            >
                <Input
                    captionText="Notes"
                    captionColor="#2a346d"
                    placeholder="Ecrire ici..."
                    // value='Ecrire ici...'
                    inputWidth="100%"
                    captionSize="12px"
                    inputRadius="4px"
                    textColor="#4C5690"
                    textSize={14}
                    iconLocation="right"
                    borderColor="#C7CBE2"
                    borderWidth={1}
                    style={{
                        inputStyle: {
                            minHeight: "48px",
                            fontWeight: "400",
                        },
                        captionStyle: {
                            fontWeight: "500",
                        },
                    }}
                />
            </div>
        </div>
    );
}

export default function AddFixedPlanCard(props: any /*AttendanceCardProps*/) {
    const {
        display,
        onClose,
        editThisShift,
        isFixedEditing,
        setIsFixedEditing,
        setEditThisShift,
    } = props;
    const { addNewFixedShift, deleteFixedShift, editFixedShift } =
        usePlanning();
    const [toggleTab, setToggleTab] = React.useState(true);
    const [editedShift, setEditedShift] = React.useState(false);
    const [shiftFixedData, setShiftFixedData] = React.useState<any>({
        from: "08:00",
        to: "16:00",
        pause: 0,
        type: 1,
        typeAbsence: "",
        comment: "",
        steaker: "",
        repas: "0",
        department: null,
    });

    const [absenceFixedData, setAbsenceFixedData] = React.useState<any>({
        from: "08:00",
        to: "16:00",
        pause: 0,
        type: 0,
        typeAbsence: "Maladie",
        comment: "",
        steaker: "",
        repas: "0",
        department: null,
    });
    React.useEffect(() => {
        if (isFixedEditing && editThisShift !== null) {
            let newObject: any = {
                planId: editThisShift._id,
                from: fomatStartEndTime(editThisShift.from),
                to: fomatStartEndTime(editThisShift.to),
                pause: editThisShift.pause,
                type: editThisShift.type,
                department: editThisShift.department,
            };
            if (editThisShift.typeAbsence) {
                newObject.typeAbsence = editThisShift.typeAbsence;
            }
            if (editThisShift.comment) {
                newObject.comment = editThisShift.comment;
            }
            if (editThisShift.steaker) {
                newObject.steaker = editThisShift.steaker._id;
            }
            if (editThisShift.repas) {
                newObject.repas = editThisShift.repas;
            }
            if (editThisShift.type === 0) {
                setAbsenceFixedData(newObject);
                setEditedShift(false);
            } else {
                setShiftFixedData(newObject);
                setEditedShift(true);
            }
        }
    }, [editThisShift, isFixedEditing]);
    const handleAddShift = () => {
        addNewFixedShift(shiftFixedData);
        handleClose();
    };
    const handleAddAbsence = () => {
        addNewFixedShift(absenceFixedData);
        handleClose();
    };
    const handleDeleteFixedShift = () => {
        deleteFixedShift(editThisShift._id);
        handleClose();
    };
    const handleModifyFixedShift = () => {
        editFixedShift(
            editThisShift.type === 1 ? shiftFixedData : absenceFixedData,
        );
        handleClose();
    };
    const handleClose = () => {
        onClose && onClose();
    };
    React.useEffect(() => {
        if (!display) {
            setShiftFixedData({
                from: "08:00",
                to: "16:00",
                pause: 0,
                type: 1,
                typeAbsence: "",
                comment: "",
                steaker: "",
                repas: "0",
                department: null,
            });
            setAbsenceFixedData({
                from: "08:00",
                to: "16:00",
                pause: 0,
                type: 0,
                typeAbsence: "Maladie",
                comment: "",
                steaker: "",
                repas: "0",
                department: null,
            });
            setEditThisShift(null);
            setIsFixedEditing(false);
        }
    }, [display]);
    const CardHeader = () => {
        return (
            <div
                style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    borderTopRightRadius: "16px",
                    overflow: "hidden",
                }}
            >
                <Button
                    backgroundColor={toggleTab ? "#2A8BAB" : "#EDEFF5"}
                    height="56px"
                    width="50%"
                    style={{ borderRadius: 0, borderTopLeftRadius: "16px" }}
                    onClick={() => {
                        setToggleTab(true);
                    }}
                >
                    <TextData
                        variant="label"
                        width="auto"
                        color={toggleTab ? "#FFFFFF" : "#7f839c"}
                        fontWeight={toggleTab ? "700" : "400"}
                        size="14px"
                        style={{
                            cursor: "pointer",
                        }}
                    >
                        Ajouter un shift
                    </TextData>
                </Button>
                <Button
                    backgroundColor={!toggleTab ? "#2A8BAB" : "#EDEFF5"}
                    height="56px"
                    width="50%"
                    style={{ borderRadius: 0 }}
                    onClick={() => {
                        setToggleTab(false);
                    }}
                >
                    <TextData
                        variant="label"
                        width="auto"
                        color={!toggleTab ? "#FFFFFF" : "#7f839c"}
                        fontWeight={!toggleTab ? "700" : "400"}
                        size="14px"
                        style={{
                            cursor: "pointer",
                        }}
                    >
                        Ajouter une absence
                    </TextData>
                </Button>
            </div>
        );
    };
    const CardEditHeader = () => {
        return (
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    margin: "25px auto 25px 40px",
                }}
            >
                <TextData
                    variant="label"
                    width="auto"
                    size="22px"
                    fontWeight="700"
                    color="#2A346D"
                >
                    Modifier {editedShift ? "le shift" : "l'absence"}
                </TextData>
            </div>
        );
    };
    return (
        <Modal modalEnabled={display} onClose={handleClose}>
            <div className="attendance-container">
                <PopUpCard
                    containerWidth="620px"
                    display={display}
                    onClose={handleClose}
                    isCloseIcon={isFixedEditing}
                    header={
                        isFixedEditing ? <CardEditHeader /> : <CardHeader />
                    }
                    widthCloseIcon="24px"
                    styleCloseIcon={{
                        padding: "15px",
                        marginRight: "25px",
                        height: "auto",
                        width: "auto",
                        borderTopRightRadius: "16px",
                    }}
                    button1Text="Annuler"
                    onClickButton1={handleClose}
                    button2Text={isFixedEditing ? "Supprimer" : "Ajouter"}
                    onClickButton2={
                        isFixedEditing
                            ? handleDeleteFixedShift
                            : toggleTab
                              ? handleAddShift
                              : handleAddAbsence
                    }
                    button2Style={
                        isFixedEditing
                            ? {
                                  backgroundColor: "#eb2f06",
                                  color: "white",
                                  fontSize: "14px",
                              }
                            : undefined
                    }
                    button3Text={isFixedEditing ? "Modifier" : undefined}
                    onClickButton3={
                        isFixedEditing ? handleModifyFixedShift : undefined
                    }
                >
                    {!isFixedEditing ? (
                        <div
                            style={{
                                height: "fit-content",
                                maxHeight: "75vh",
                                overflow: "auto",
                            }}
                        >
                            {toggleTab ? (
                                <AddShift
                                    shiftFixedData={shiftFixedData}
                                    setShiftFixedData={setShiftFixedData}
                                />
                            ) : (
                                <AddAbsence
                                    absenceFixedData={absenceFixedData}
                                    setAbsenceFixedData={setAbsenceFixedData}
                                />
                            )}
                        </div>
                    ) : (
                        <div
                            style={{
                                height: "75vh",
                                overflow: "auto",
                            }}
                        >
                            {editedShift ? (
                                <AddShift
                                    editedShift={editedShift}
                                    shiftFixedData={shiftFixedData}
                                    setShiftFixedData={setShiftFixedData}
                                />
                            ) : (
                                <AddAbsence
                                    absenceFixedData={absenceFixedData}
                                    setAbsenceFixedData={setAbsenceFixedData}
                                />
                            )}
                        </div>
                    )}
                </PopUpCard>
            </div>
        </Modal>
    );
}

interface AddFixedPlanCardProps {
    display?: boolean;
    onClose?: (event?: Event) => void;
    withEmployees: boolean;
    employees?: any;
    defaultEmployee?: any;
    day?: string;
    dayOrder?: number;
}
